import { gql } from '@apollo/client'

export const CREATE_SELLER = gql`
  mutation CreateSeller(
    $name: String!
    $email: String!
    $address: String!
    $phone: String!
    $dealerId: ID!
    $userId: ID!
  ) {
    createSeller(
      input: {
        data: {
          name: $name
          email: $email
          address: $address
          phone: $phone
          dealer: $dealerId
          users_permissions_user: $userId
        }
      }
    ) {
      seller {
        id
      }
    }
  }
`

export type TCreateSellerResponse = {
  createSeller: {
    seller: {
      id: string,
    },
  },
}
