import { gql } from '@apollo/client'

export const ARTICLE = gql`
  query GETArticle($id: ID!) {
    article(id: $id) {
      title
      text
      media {
        url
      }
      date
      expire_date
      order
    }
  }
`


type TArticle = {
  date: string,
  expire_date: string,
  media: {
    url: string,
  },
  order: number,
  text: string,
  title: string,
}

export type TArticleResponse = {
  article: TArticle,
}
