import { parseCookies } from 'nookies'
import parseInt from 'lodash/parseInt'

import { AuthAPI } from './models/API'

export const AuthStore = AuthAPI.create({
  email: parseCookies().email || '',
  id: parseInt(parseCookies().id) || 0,
  token: parseCookies().token || '',
})
