import React, {
  BaseSyntheticEvent,
  useEffect,
  useState,
} from 'react'
import { observer } from 'mobx-react-lite'

import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import { useStore, useDebounce } from 'hooks'

import {
  TResult,
  TCoords,
  geocoder,
} from './helpers'
import { DEFAULT_MAP_CENTER } from './config'
import { Map } from './components/Map'
import css from './AddressField.module.css'

const DEBOUNCE_DELAY = 250

const AddressFieldFC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isNeedFetching, setIsNeedFetching] = useState(false)
  const [autocompleteVariants, setAutocompleteVariants] = useState<TResult>([])
  const [markerCoords, setMarkerCoords] = useState<TCoords>(DEFAULT_MAP_CENTER)

  const { editAdvert: { address, setAddress } } = useStore()

  const debouncedAddress = useDebounce(address, DEBOUNCE_DELAY)

  useEffect(() => {
    if (debouncedAddress && isNeedFetching) {
      geocoder.getAutocompleteResults(debouncedAddress)
        .then(data => setAutocompleteVariants(data.items))
    } else {
      setAutocompleteVariants([])
    }
  }, [debouncedAddress, isNeedFetching])


  return (
    <div className={css.container}>
      <div className={css.title}>
        Адрес осмотра
      </div>
      <div className={css.inputContainer}>
        <input
          className={css.input}
          type='text'
          value={address}
          onChange={(e: BaseSyntheticEvent) => setAddress(e.target.value)}
          onFocus={() => setIsNeedFetching(true)}
        />
        {!isEmpty(autocompleteVariants) && (
          <div className={css.variantsList}>
            {map(autocompleteVariants, ({ position, title }) => (
              <div
                key={title}
                className={css.variant}
                onClick={() => {
                  setAddress(title)
                  setMarkerCoords(position)
                  setAutocompleteVariants([])
                  setIsNeedFetching(false)
                }}
              >
                {title}
              </div>
            ))}
          </div>
        )}
        <div className={css.openMapButton} onClick={() => setIsOpen(!isOpen)}>
          {!isOpen ? 'Выбрать на карте' : 'Скрыть карту'}
        </div>
      </div>
      {isOpen && <Map coords={markerCoords} setCoords={setMarkerCoords} />}
    </div>
  )
}

export const AddressField = React.memo(observer(AddressFieldFC))
