import { gql } from '@apollo/client'

export const CONTACTS = gql`
  query GetContacts($limit: Int, $start: Int, $where: JSON, $sort: String) {
    contacts(limit: $limit, start: $start, where: $where, sort: $sort) {
      id
      address
      email
      name
      phone
      photo {
        url
      }
    }
  }
`

type TContact = {
  address: string,
  email: string,
  id: string,
  name: string,
  phone: string,
  photo: {
    url: string,
  },
}

export type TContactsResponse = {
  contacts: Array<TContact>,
}
