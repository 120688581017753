import { setCookie, destroyCookie } from 'nookies'
import forEach from 'lodash/forEach'

import { AuthViews } from './Views'

type TSaveCookie = {
  email: string,
  id: string,
  token: string,
}

export const AuthSetters = AuthViews.actions(self => ({
  removeCookie() {
    destroyCookie(null, 'token')
    destroyCookie(null, 'email')
    destroyCookie(null, 'id')
  },
  saveCookie(fields: TSaveCookie) {
    forEach(fields, (value, fieldName) => {
      setCookie(
        null,
        fieldName,
        value,
      )
    })
  },
  setEmail(email: string) {
    self.email = email
  },
  setId(id: number) {
    self.id = id
  },
  setMessage(message: string) {
    self.message = message
  },
  setPassword(password: string) {
    self.password = password
  },
  setToken(token: string) {
    self.token = token
  },
})).actions(self => ({
  logout() {
    self.setEmail('')
    self.setMessage('')
    self.setPassword('')
    self.setToken('')
    self.removeCookie()
  },
}))
