import { types } from 'mobx-state-tree'

const {
  array,
  map,
  model,
  number,
  string,
} = types

const CheckedOptions = array(string)

const OptionsFilters = map(CheckedOptions)

const RangeFilter = model({
  from: number,
  max: number,
  min: number,
  to: number,
})

const RangeFilters = map(RangeFilter)

export const FilterMain = model({
  optionsFilters: OptionsFilters,
  pageNumber: 1,
  rangeFilters: RangeFilters,
  resultsPerPage: number,
})
