import React, { useState, useMemo } from 'react'

import map from 'lodash/map'
import find from 'lodash/find'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import cx from 'classnames'

import { OutsideClick } from 'components/common/OutsideClick'

import { TDropdownOption } from './types'
import css from './Dropdown.module.css'

type TDropdownProps = {
  defaultOptionId?: string,
  handleChange: (value: string) => void,
  onFocus?: () => void,
  options: Array<TDropdownOption>,
  validationError?: boolean,
}

const DropdownFC = ({
  defaultOptionId,
  handleChange,
  onFocus,
  options,
  validationError,
}: TDropdownProps) => {
  const sortedOptions = useMemo(()=>sortBy(options, ['title']), [options]);
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(get(
    find(sortedOptions, ({ id }) => id === defaultOptionId),
    'title',
    '',
  ))

  return (
    <div
      className={cx(css.container, { [css.validationError]: validationError })}
      onFocus={onFocus}
    >
      <button className={css.triggerButton} onClick={() => setIsOpen(!isOpen)}>
        {selectedOption}
        <span className={css.caret} />
      </button>
      {isOpen && (
        <OutsideClick onClick={() => setIsOpen(false)}>
          <ul className={css.optionsList}>
            {map(sortedOptions, ({ id, title }) => (
              <li
                className={css.option}
                key={id}
                onClick={() => {
                  setSelectedOption(title)
                  handleChange(id)
                  setIsOpen(false)
                }}
              >
                {title}
              </li>
            ))}
          </ul>
        </OutsideClick>
      )}
    </div>
  )
}

export const Dropdown = React.memo(DropdownFC)
