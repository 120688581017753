import React from 'react'
import { useQuery } from '@apollo/client'

import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'

import { BASE_URL } from 'config/URLs'
import {
  CONTACTS,
  TContactsResponse,
  GEOGRAPHY,
  TGeographyResponse,
} from 'requests/queries'

import { ContactCard } from './components/ContactCard'
import css from './Contacts.module.css'

const ContactsFC = () => {
  const { data } = useQuery<TContactsResponse>(CONTACTS)
  const geographyResponse = useQuery<TGeographyResponse>(GEOGRAPHY)

  return (
    <div className={css.container}>
      <div className={css.title}>
        Контактные лица
      </div>
      <div className={css.contactCardsContainer}>
        {data && map(data.contacts, contact => (
          <ContactCard
            key={contact.id}
            address={contact.address}
            email={contact.email}
            name={contact.name}
            phone={contact.phone}
            photo={contact.photo.url}
          />
        ))}
      </div>
      <div className={css.title}>
        Карта региональных представителей
      </div>
      {geographyResponse.data && !isEmpty(geographyResponse.data.geographies) && (
        <img
          src={`${BASE_URL}${geographyResponse.data.geographies[0].image.url}`}
          className={css.map}
          alt='не удалось загрузить карту'
        />
      )}
    </div>
  )
}

export const Contacts = React.memo(ContactsFC)
