import React, {
  BaseSyntheticEvent,
  useState,
  useEffect,
} from 'react'

import cx from 'classnames'

import css from './Input.module.css'

type TInputProps = {
  initialValue?: string,
  setGetValue: Function,
  title: string,
  validateValue: (s: string) => boolean,
}

const InputFC = ({
  initialValue,
  setGetValue,
  title,
  validateValue,
}: TInputProps) => {
  const [value, setValue] = useState('')
  const [validationError, setValidationError] = useState(false)

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue)
    }
  }, [initialValue])

  useEffect(() => {
    setGetValue(() => () => {
      if (!validateValue(value)) {
        setValidationError(true)
        return ''
      }
      return value
    })
  }, [setGetValue, validateValue, value])

  return (
    <div className={css.container}>
      <div className={css.inputTitle}>
        {title}
      </div>
      <input
        className={cx(css.input, { [css.validationError]: validationError })}
        onFocus={() => setValidationError(false)}
        value={value}
        onChange={(e: BaseSyntheticEvent) => setValue(e.target.value)}
      />
    </div>
  )
}

export const Input = React.memo(InputFC)
