import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import Grid from '@material-ui/core/Grid'
import map from 'lodash/map'

import { useStore } from 'hooks'

import { FILTERS_LIST } from './config'
import { OptionsFilter } from './components/OptionsFilter'
import { RangeFilter } from './components/RangeFilter'
import css from './Filter.module.css'

const FilterFC = () => {
  const [showFilters, setShowFilters] = useState(true)
  const isSmallRes = useMediaQuery('(max-width:960px)')
  const {
    filter: {
      parseQueryString,
      queryParamsString,
      reset,
    },
  } = useStore()
  const history = useHistory()

  useEffect(() => {
    parseQueryString(history.location.search.slice(1))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    history.push({
      search: `?${queryParamsString}`,
    })
  }, [history, queryParamsString])

  return (
    <div className={css.container}>
      {isSmallRes && (
        <div
          className={css.button}
          onClick={() => setShowFilters(!showFilters)}
        >
          {showFilters ? 'Скрыть фильтры' : 'Показать фильтры'}
        </div>
      )}
      {showFilters && (
        <div
          className={css.button}
          onClick={reset}
        >
          Очистить фильтры
        </div>
      )}
      <Grid container spacing={2}>
        {showFilters && map(FILTERS_LIST, filter => (
          <Grid item xs={12} sm={6} md={12} key={filter.title}>
            {filter.type === 'options'
              ? (
                <OptionsFilter
                  title={filter.title}
                  collectionName={filter.collectionName}
                />
              )
              : (
                <RangeFilter
                  getValueText={filter.getValueText}
                  marksLabels={filter.marksLabels}
                  maxValue={filter.maxValue}
                  minValue={filter.minValue}
                  param={filter.param}
                  step={filter.step}
                  title={filter.title}
                />
              )
            }
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

export const Filter = React.memo(observer(FilterFC))
