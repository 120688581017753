import React, { Fragment } from 'react'

import map from 'lodash/map'
import get from 'lodash/get'

import { BASE_URL } from 'config/URLs'
import { getCurrencySymbol } from 'helpers'

import { TComponentProps } from '../../types'
import { INFO_BLOCKS } from '../../config'
import { enhanceDate } from './helpers'
import { useInfoBlock } from './hooks'
import css from './InfoBlock.module.css'

const InfoBlockFC = ({ advert }: TComponentProps) => {
  const {
    handleButtonDownload,
    handleButtonShare,
    shareButtonText,
  } = useInfoBlock(advert.model.title)

  return (
    <Fragment>
      <div className={css.title}>
        {`${advert.price.toLocaleString()} ${getCurrencySymbol(advert.currency)}`}
      </div>
      <div className={css.infoBlock}>
        <div className={css.infoBlockTitle}>
          Место расположения техники:
        </div>
        <div className={css.infoBlockMain}>
          <div className={css.countryname}>
            <img
              className={css.flag}
              src={`${BASE_URL}${advert.country.flag.url}`}
              alt='Не удалось загрузить флаг'
            />
            {advert.country.title}
          </div>
          {advert.address || ''}
        </div>
      </div>
      <div className={css.infoBlock}>
        <div className={css.infoBlockTitle}>
          Дата размещения:
        </div>
        <div className={css.infoBlockMain}>
          {enhanceDate(advert.published_at)}
        </div>
      </div>
      {map(INFO_BLOCKS, ({ path, title }) => (
        <div className={css.infoBlock} key={title}>
          <div className={css.infoBlockTitle}>
            {title}
          </div>
          <div className={css.infoBlockMain}>
            {get(advert, path)}
          </div>
        </div>
      ))}
      <div className={css.infoBlock}>
        <div className={css.infoBlockTitle}>
          Видео:
        </div>
        <div className={css.infoBlockMain}>
          <a href={advert.video}>
            {advert.video || ''}
          </a>
        </div>
      </div>
      <div className={css.infoBlock}>
        <div className={css.infoBlockTitle}>
          {`Наработка, ${advert.operating_time_units === 'hours'
            ? 'ч'
            : 'га'}`}
        </div>
        <div className={css.infoBlockMain}>
          {advert.operating_time}
        </div>
      </div>
      <div className={css.buttonsBlock}>
        <div className={css.button} onClick={handleButtonDownload}>Скачать в PDF</div>
        <div className={css.button} onClick={handleButtonShare}>{shareButtonText}</div>
      </div>
    </Fragment>
  )
}

export const InfoBlock = React.memo(InfoBlockFC)
