import { gql } from '@apollo/client'

export const getFilterOptions = (collectionName: string) => gql`
  query GetModels {
    ${collectionName} {
      id
      title
      __typename
    }
  }
`
