import React, { useState } from 'react'
import { useQuery } from '@apollo/client'

import map from 'lodash/map'

import { SELLERS, TSellersResponse } from 'requests/queries'
import { useStore } from 'hooks'
import { OutsideClick } from 'components/common/OutsideClick'

import css from './AddSellers.module.css'

const AddSellersFC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { editAdvert: { addSeller } } = useStore()

  const {
    data,
    error,
    loading,
  } = useQuery<TSellersResponse>(SELLERS)


  return (
    <div className={css.container}>
      <div className={css.button} onClick={() => setIsOpen(!isOpen)}>
        Добавить
      </div>
      {isOpen && !error && !loading && (
        <OutsideClick onClick={() => setIsOpen(false)}>
          <div className={css.sellersListContainer}>
            {map(data?.sellers, seller => (
              <div className={css.sellerContainer} key={seller.id}>
                {seller.name}
                <div className={css.secondaryText}>
                  {seller.email}
                </div>
                <div
                  className={css.buttonAdd}
                  onClick={() => addSeller(seller)}
                />
              </div>
            ))}
          </div>
        </OutsideClick>
      )}
    </div>
  )
}

export const AddSellers = React.memo(AddSellersFC)
