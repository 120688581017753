import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { observer } from 'mobx-react-lite'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import Grid from '@material-ui/core/Grid'
import map from 'lodash/map'

import { ADVERTS, TAdvertsResponse } from 'requests/queries'
import { useStore, useDebounce } from 'hooks'

import { Advert } from './components/Advert'
import { Filter } from './components/Filter'
import { Categories } from './components/Categories'
import { Pagination } from './components/Pagination'
import css from './MainPage.module.css'

const MainPageFC = () => {
  const [category, setCategory] = useState('')
  const { filter: { apolloFilter } } = useStore()
  const smallRes = useMediaQuery('(max-width:960px)')

  const [filter] = useState(apolloFilter) // иначе обс заново вызывает useQuery
  const { data, refetch } = useQuery<TAdvertsResponse>(ADVERTS, { variables: filter })

  const debouncedFilter = useDebounce(apolloFilter, 200)

  useEffect(() => {
    refetch(debouncedFilter)
  }, [debouncedFilter, refetch])

  return (
    <div className={css.container}>
      <Categories setActiveCategory={setCategory} />
      <div className={css.catalogContainer}>
        <div className={css.categoryName}>
          {`Каталог${category ? ' / ' : ''}${category}`}
        </div>
        <Grid
          container
          direction={smallRes ? 'column-reverse' : 'row'}
          justify='space-evenly'
          spacing={3}
          className={css.catalogMainSection}
        >
          <Grid
            item
            sm={12}
            md={8}
            lg={9}
            className={css.advertsContainer}
            zeroMinWidth
          >
            <Grid container spacing={3}>
              {data && map(
                data.adverts,
                advert => (
                  <Grid item xs={12} key={String(advert.id)}>
                    <Advert
                      address={advert.address || ''}
                      currency={advert.currency}
                      id={advert.id}
                      imagesURLs={map(advert.photos, ({ url }) => url)}
                      model={advert.model
                        ? advert.model.title
                        : 'Неизвестная модель'}
                      price={advert.price}
                      workingWidth={advert.working_width}
                      year={advert.production_year}
                    />
                  </Grid>
                ),
              )}
              <Grid item xs={12}>
                <Pagination />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} md={4} lg={3} zeroMinWidth>
            <Link
              to='/createAdvert'
              className={css.addAdvertButton}
            >
              Создать объявление
            </Link>
            <Filter />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export const MainPage = React.memo(observer(MainPageFC))
