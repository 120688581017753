import React from 'react'
import { useQuery } from '@apollo/client'

import Grid from '@material-ui/core/Grid'
import map from 'lodash/map'
import replace from 'lodash/replace'

import { VIDEOS } from 'requests/queries'

import css from './Videos.module.css'

const VideosFC = () => {
  const {
    data,
    error,
    loading,
  } = useQuery(VIDEOS)

  return (
    <div className={css.container}>
      <div className={css.title}>
        Записи последних вебинаров для отделов продаж
      </div>
      <Grid
        container
        spacing={10}
        className={css.playersContainer}
        justify='center'
      >
        {!loading && !error && map(data.videos, ({ id, url }) => (
          <Grid
            key={String(id)}
            item
            xs={12}
            sm={6}
            md={5}
            lg={4}
          >
            <div className={css.playerWrapper}>
              <div className={css.playerContainer}>
                <iframe
                  width='100%'
                  height='100%'
                  src={replace(
                    url,
                    'watch?v=',
                    'embed/',
                  )}
                  title='YouTube video player'
                  frameBorder='0'
                  allow='accelerometer;
                        autoplay;
                        clipboard-write;
                        encrypted-media;
                        gyroscope;
                        picture-in-picture'
                  allowFullScreen
                />
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

export const Videos = React.memo(VideosFC)
