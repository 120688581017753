import { gql } from '@apollo/client'

export const ARTICLES = gql`
  query GetArticles($limit: Int, $start: Int, $where: JSON, $sort: String) {
    articles(limit: $limit, start: $start, where: $where, sort: $sort) {
      id
      title
      text
      media {
        url
      }
      date
      expire_date
    }
  }
`

type TArticle = {
  date: string,
  expire_date: string,
  id: string,
  media: {
    url: string,
  },
  text: string,
  title: string,
}

export type TArticlesResponse = {
  articles: Array<TArticle>,
}
