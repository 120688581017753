import React from 'react'
import { useQuery } from '@apollo/client'

import Grid from '@material-ui/core/Grid'
import map from 'lodash/map'
import toLower from 'lodash/toLower'

import { BASE_URL } from 'config/URLs'
import { TYPES } from 'requests/queries'
import { useStore } from 'hooks'

import css from './Categories.module.css'

type TCategoriesProps = {
  setActiveCategory: (title: string) => void,
}

const CategoriesFC = ({ setActiveCategory }: TCategoriesProps) => {
  const { filter: { setSingleOptionInFilter } } = useStore()
  const { data, loading } = useQuery(TYPES)

  return (
    <div className={css.container}>
      <Grid container spacing={3} justify='center'>
        {!loading && map(data.types, ({
          __typename,
          id,
          image,
          title,
        }, idx) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            xl={2}
            key={String(idx)}
            className={css.imageContainer}
          >
            <img
              onClick={() => {
                setSingleOptionInFilter(
                  toLower(__typename),
                  String(id),
                )
                setActiveCategory(title)
              }}
              className={css.image}
              src={`${BASE_URL}${image.url}`}
              alt='Ooops'
            />
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

export const Categories = React.memo(CategoriesFC)
