import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import Grid from '@material-ui/core/Grid'
import replace from 'lodash/replace'
import parseInt from 'lodash/parseInt'

import { ADVERT, TAdvert } from 'requests/queries'

import { ADVERT_CONTAINER_ID } from './config'
import { Contacts } from './components/Contacts'
import { InfoBlock } from './components/InfoBlock'
import { Images } from './components/Images'
import css from './Advert.module.css'

const AdvertFC = () => {
  const { location } = useHistory()
  const advertID = useMemo(() =>
    parseInt(replace(
      location.pathname,
      '/advert/',
      '',
    )), [location])

  const {
    data,
    error,
    loading,
  } = useQuery<{advert: TAdvert}>(ADVERT, { variables: { id: advertID } })

  const advert = useMemo(() => data?.advert, [data])

  return (
    <div id={ADVERT_CONTAINER_ID}>
      {!loading && !error && advert && (
        <div className={css.container}>
          <div className={css.title}>
            {`${advert.type.title} ${advert.model.title}`}
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={9}>
              <div className={css.section}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={4}>
                    <InfoBlock advert={advert} />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Images advert={advert} />
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Contacts advert={advert} />
            </Grid>
            <Grid item xs={12} lg={9}>
              <div className={css.section}>
                <div className={css.boldSpan}>
                  Дополнительная информация:
                </div>
                {advert.extra_info || ''}
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  )
}

export const Advert = React.memo(AdvertFC)
