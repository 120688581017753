import React, { BaseSyntheticEvent } from 'react'
import { observer } from 'mobx-react-lite'

import cx from 'classnames'

import { ISimpleField } from 'stores/EditAdvert/models/Main'
import { Dropdown } from 'components/common/Dropdown'

import { FetchingDropdown } from '../FetchingDropdown'
import css from './SimpleField.module.css'

type TSimpleFieldProps = {
  field: ISimpleField,
}

const SimpleFieldFC = ({ field }: TSimpleFieldProps) => {
  const {
    isRequired,
    selectedUnitId,
    setSelectedUnitId,
    setValidationError,
    setValue,
    title,
    type,
    units,
    validationError,
    value,
  } = field

  const onChange = (e: BaseSyntheticEvent) => setValue(e.target.value)
  const onFocus = () => setValidationError(false)
  const andValidationError = (cssClass: string) => cx(
    cssClass,
    { [css.validationError]: validationError },
  )

  return (
    <div className={css.container}>
      <div className={css.title}>
        {`${title}${isRequired ? '*' : ''}`}
      </div>
      <div className={css.inputContainer}>
        {
          {
            dropdown: <FetchingDropdown field={field} />,
            number: (
              <input
                className={andValidationError(css.input)}
                onFocus={onFocus}
                type={'number'}
                step='0.01'
                value={value}
                onChange={onChange}
              />
            ),
            string: (
              <input
                className={andValidationError(css.input)}
                onFocus={onFocus}
                type={'text'}
                value={value}
                onChange={onChange}
              />
            ),
            with_units: (
              <div className={css.complexFieldContainer}>
                <input
                  className={andValidationError(css.complexFieldInput)}
                  onFocus={onFocus}
                  type='number'
                  value={value}
                  onChange={onChange}
                />
                <div className={css.complexFieldDropdownContainer}>
                  <Dropdown
                    defaultOptionId={selectedUnitId}
                    handleChange={setSelectedUnitId}
                    onFocus={onFocus}
                    options={units}
                    validationError={validationError}
                  />
                </div>
              </div>
            ),
          }[type]
        }
      </div>
    </div>
  )
}

export const SimpleField = React.memo(observer(SimpleFieldFC))
