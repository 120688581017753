import { gql } from '@apollo/client'

export const UPDATE_ADVERT_HIDDEN = gql`
  mutation UpdateAdvert(
    $id: ID!
    $is_hidden: Boolean
  ) {

    updateAdvert(
      input: {
        where: {
          id: $id
        }
        data: {
          is_hidden: $is_hidden
        }
      }
    ) {
      advert {
        id
      }
    }
  }
`
