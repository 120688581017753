import React, { useEffect } from 'react'
import {
  MapContainer,
  TileLayer,
  Marker,
  useMap,
  useMapEvent,
} from 'react-leaflet'

import { useStore } from 'hooks'

import { DEFAULT_MAP_CENTER } from '../../config'
import { TCoords, geocoder } from '../../helpers'
import css from './Map.module.css'

type TMapProps = {
  coords: TCoords,
  setCoords: (coords: TCoords) => void,
}

type TMapClick = { latlng: TCoords }

const MapEvents = ({ coords, setCoords }: TMapProps) => {
  const { editAdvert: { setAddress } } = useStore()
  const leafletMap = useMap()

  useMapEvent('click', ({ latlng }: TMapClick) => {
    setCoords(latlng)
    geocoder.getAdress(latlng).then(data => setAddress(data.items[0].title))
  })

  useEffect(() => {
    leafletMap.flyTo(coords)
  }, [coords, leafletMap])

  return null
}

const MapFC = ({ coords, setCoords }: TMapProps) => (
  <MapContainer
    center={coords}
    zoom={13}
    scrollWheelZoom={false}
    className={css.mapContainer}
  >
    <TileLayer
      // eslint-disable-next-line max-len
      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
    />
    {coords !== DEFAULT_MAP_CENTER && <Marker position={coords} />}
    <MapEvents coords={coords} setCoords={setCoords} />
  </MapContainer>
)

export const Map = React.memo(MapFC)
