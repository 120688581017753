import React, { useRef, ReactNode } from 'react'

import { useOutsideClickEffect } from './hooks'

export type TOutsideClick = {
  children: ReactNode,
  onClick: Function,
}

export const OutsideClick = ({
  children,
  onClick,
}: TOutsideClick) => {
  const wrapperRef = useRef<HTMLDivElement>(null)

  useOutsideClickEffect({ onClick, wrapperRef })

  return (
    <div ref={wrapperRef}>
      {children}
    </div>
  )
}
