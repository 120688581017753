import React, {
  useState,
  useMemo,
  useCallback,
  BaseSyntheticEvent,
} from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import parseInt from 'lodash/parseInt'
import replace from 'lodash/replace'
import parse from 'html-react-parser'

import {
  ARTICLE,
  TArticleResponse,
  ARTICLES,
  TArticlesResponse,
} from 'requests/queries'
import { BASE_URL, IMAGE_PLACEHOLDER_URL } from 'config/URLs'

import css from './Article.module.css'

const ArticleFC = () => {
  const { location } = useHistory()
  const [articleID, setArticleID] = useState(parseInt(replace(
    location.pathname,
    '/article/',
    '',
  )))

  const { data } = useQuery<TArticleResponse>(ARTICLE, { variables: { id: articleID } })

  const prevArticle = useQuery<TArticlesResponse>(
    ARTICLES,
    { variables: { sort: 'order:DESC', where: { order_lt: data?.article.order } } },
  )
  const nextArticle = useQuery<TArticlesResponse>(
    ARTICLES,
    { variables: { sort: 'order', where: { order_gt: data?.article.order } } },
  )

  const prevArticleId = useMemo(() => prevArticle.data?.articles[0]?.id, [prevArticle.data])
  const nextArticleId = useMemo(() => nextArticle.data?.articles[0]?.id, [nextArticle.data])

  const handleLinkClick = useCallback((articleId: string) => {
    setArticleID(parseInt(articleId))
    window.scrollTo({
      behavior: 'smooth',
      top: 300,
    })
  }, [])

  if (!data) return <div>Идет загрузка...</div>

  return (
    <div className={css.container} id='articleContainer'>
      <div className={css.date}>
        {data.article.date}
        {data.article.expire_date && (
          <div className={css.expireDate}>
            {`Действует до ${data.article.expire_date}`}
          </div>
        )}
      </div>
      <div className={css.title}>
        {data.article.title}
      </div>
      <img
        src={`${BASE_URL}${data.article.media.url}`}
        className={css.photo}
        onError={(e: BaseSyntheticEvent) => {
          e.target.error = null
          e.target.src = IMAGE_PLACEHOLDER_URL
        }}
        alt='не удалось загрузить фото'
      />
      <div className={css.text}>
        {parse(data.article.text)}
      </div>
      <div className={css.nav}>
        {prevArticleId && (
          <div className={css.linkContainer}>
            <Link
              className={css.link}
              to={`/article/${prevArticleId}`}
              onClick={() => handleLinkClick(prevArticleId)}
            >
              Предыдущая новость
            </Link>
          </div>
        )}
        {nextArticleId && (
          <div className={css.linkContainer}>
            <Link
              className={css.link}
              to={`/article/${nextArticleId}`}
              onClick={() => handleLinkClick(nextArticleId)}
            >
              Следующая новость
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

export const Article = React.memo(ArticleFC)
