import { gql } from '@apollo/client'

export const DELETE_ADVERT = gql`
  mutation DeleteAdvert($id: ID!) {
    deleteAdvert(
      input: {
        where: {
          id: $id
        }
      }
    )
    {
      advert {
        id
      }
    }
  }
`
