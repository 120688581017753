import React, {
  MouseEventHandler,
  useState,
  useEffect,
} from 'react'

import Select, {
  components,
  MultiValueGenericProps,
  MultiValueProps,
  OnChangeValue,
  Props,
} from 'react-select'
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortEndHandler,
  SortableHandle,
} from 'react-sortable-hoc'

type TOption = {
  label?: string,
  value: string,
}

function arrayMove<T>(
  array: ReadonlyArray<T>,
  from: number,
  to: number,
) {
  const slicedArray = array.slice()
  slicedArray.splice(
    to < 0 ? array.length + to : to,
    0,
    slicedArray.splice(from, 1)[0],
  )
  return slicedArray
}

const SortableMultiValue = SortableElement(
  (props: MultiValueProps<TOption>) => {
    // this prevents the menu from being opened/closed when the user clicks
    // on a value to begin dragging it. ideally, detecting a click (instead of
    // a drag) would still focus the control and toggle the menu, but that
    // requires some magic with refs that are out of scope for this example
    const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
      e.preventDefault()
      e.stopPropagation()
    }
    const innerProps = { ...props.innerProps, onMouseDown }
    return <components.MultiValue {...props} innerProps={innerProps} />
  },
)

const SortableMultiValueLabel = SortableHandle(
  (props: MultiValueGenericProps) => <components.MultiValueLabel {...props} />,
)

const SortableSelect = SortableContainer(Select) as React.ComponentClass<
Props<TOption, true> & SortableContainerProps
>

interface IProps {
  onChange: (values: ReadonlyArray<TOption>) => void,
  options: ReadonlyArray<TOption>,
  placeholder: string,
  values: ReadonlyArray<TOption>,
}

export const Multiselect = React.memo<IProps>(({
  onChange,
  options,
  placeholder,
  values,
}: IProps) => {
  const [selected, setSelected] = useState<ReadonlyArray<TOption>>(values)

  const handleChange = (selectedOptions: OnChangeValue<TOption, true>) => {
    setSelected(selectedOptions)
    onChange(selectedOptions)
  }

  const onSortEnd: SortEndHandler = ({ newIndex, oldIndex }) => {
    const newValue = arrayMove(
      selected,
      oldIndex,
      newIndex,
    )
    setSelected(newValue)
  }

  useEffect(() => {
    setSelected(values)
  }, [values])

  return (
    <SortableSelect
      placeholder={placeholder}
      useDragHandle
      axis='xy'
      onSortEnd={onSortEnd}
      distance={4}
      getHelperDimensions={({ node }) => node.getBoundingClientRect()}
      isMulti
      options={options}
      value={selected}
      onChange={handleChange}
      components={{
        // @ts-ignore We're failing to provide a required index prop to SortableElement
        MultiValue: SortableMultiValue,
        MultiValueLabel: SortableMultiValueLabel,
      }}
      closeMenuOnSelect={false}
    />
  )
})
