/* eslint-disable max-len */
export const LOCAL_URL = 'http://localhost:1337'

const PROD_URL = 'https://api.amazone.team'

export const BASE_URL = PROD_URL
// process.env.REACT_APP_API === 'local'
//   ? LOCAL_URL
//   : PROD_URL

export const IMAGE_PLACEHOLDER_URL = `${BASE_URL}/uploads/image_placeholder_99f9d8cf7a.jpg`
