import React from 'react'
import { Link } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'
import cx from 'classnames'

import { Videos } from './components/Videos'
import css from './Footer.module.css'

const FooterFC = () => (
  <div className={css.container}>
    <Videos />
    <div className={css.footerSection}>
      <div className={css.backToTop}>
        <div
          className={css.backToTopButton}
          onClick={() => window.scrollTo({
            behavior: 'smooth',
            top: 0,
          })}
        >
          Вверх
        </div>
      </div>
      <Grid container spacing={0} className={css.footerInfo}>
        <div className={css.logo} />
        <Grid item sm={6} md={4}>
          <div className={css.infoSectionTitle}>
            Амазоне ООО
          </div>
          г. Подольск, ул. Комсомольская, д.1, корпус Бытовой, офис 704
          142100, Московская область, г. Подольск
        </Grid>
        <Grid item sm={6} md={3}>
          <div className={css.infoSectionTitle}>
            Ссылки
          </div>
          <Link
            to='/news'
            className={css.link}
          >
            Новости
          </Link>
          <br />
          <Link
            to='/promotions'
            className={css.link}
          >
            Акции
          </Link>
          <br />
          <Link
            to='/contacts'
            className={css.link}
          >
            Контакты
          </Link>
        </Grid>
        <Grid item sm={6} md={5}>
          <div className={css.infoSectionTitle}>
            {'Контакты & Информация'}
          </div>
          <div className={css.socialLinks}>
            <div className={css.socialLinkWrapper}>
              <a
                className={css.socialLink}
                href='https://www.facebook.com/amazonerussia/'
                target='_blank'
                rel='noreferrer'
              >
                <div className={cx(css.socialImage, css.facebook)} />
                Facebook
              </a>
            </div>
            <div className={css.socialLinkWrapper}>
              <a
                className={css.socialLink}
                href={
                  'https://www.youtube.com/channel/UC7rTdG5plsQeKOZc8hHH2eQ'}
                target='_blank'
                rel='noreferrer'
              >
                <div className={cx(css.socialImage, css.youtube)} />
                Youtube
              </a>
            </div>
            <div className={css.socialLinkWrapper}>
              <a
                className={css.socialLink}
                href='https://www.instagram.com/amazonerussia/'
                target='_blank'
                rel='noreferrer'
              >
                <div className={cx(css.socialImage, css.instagram)} />
                Instagram
              </a>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  </div>
)

export const Footer = React.memo(FooterFC)
