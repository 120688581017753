import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { useQuery } from '@apollo/client'

import { ISimpleField } from 'stores/EditAdvert/models/Main'
import { Dropdown } from 'components/common/Dropdown'
import { getFilterOptions } from 'requests/templates'

import { enhanceFieldName } from './helpers'

type TFetchingDropdownProps = {
  field: ISimpleField,
}

type TResponseItem = {
  id: string,
  title: string,
}

type TResponse = {
  [key: string]: Array<TResponseItem>,
}

const FetchingDropdownFC = ({ field }: TFetchingDropdownProps) => {
  const {
    fieldName,
    setValidationError,
    setValue,
    validationError,
    value,
  } = field

  const enhancedFieldName = useMemo(
    () => enhanceFieldName(fieldName),
    [fieldName],
  )

  const {
    data,
    error,
    loading,
  } = useQuery<TResponse>(getFilterOptions(enhancedFieldName))

  if (error) {
    return (<div>{`Не удалось загрузить ${fieldName}`}</div>)
  }

  return loading
    ? <div>Загрузка</div>
    : (
      <Dropdown
        defaultOptionId={value}
        handleChange={setValue}
        onFocus={() => setValidationError(false)}
        options={data ? data[enhancedFieldName] : []}
        validationError={validationError}
      />
    )
}

export const FetchingDropdown = React.memo(observer(FetchingDropdownFC))
