import { gql } from '@apollo/client'

export const TYPES = gql`
  query GetTypes {
    types {
      id
      title
      __typename
      image {
        url
      }
    }
  }
`
