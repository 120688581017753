import { AuthMain } from './Main'
import { emailReg } from '../helpers'

export const AuthViews = AuthMain.views(self => ({
  get isAuth() {
    return Boolean(self.token)
  },
  get isEmailValid() {
    return emailReg.test(self.email)
  },
  get isPasswordValid() {
    return self.password.length > 6
  },
}))
  .views(self => ({
    get isFormValid() {
      return self.isEmailValid && self.isPasswordValid
    },
  }))
