import React, { useCallback } from 'react'
import { useMutation } from '@apollo/client'

import { DELETE_ADVERT } from 'requests/mutations'

import css from './ConfirmPopup.module.css'

type TConfirmPopupProps = {
  advertId: number,
  close: () => void,
  refetch: Function,
}

const ConfirmPopupFC = ({
  advertId,
  close,
  refetch,
}: TConfirmPopupProps) => {
  const [deleteAdvert] = useMutation(DELETE_ADVERT, {
    onCompleted: () => refetch(),
    variables: { id: advertId },
  })

  const deleteHandler = useCallback(() => {
    deleteAdvert()
    close()
  }, [close, deleteAdvert])

  return (
    <div className={css.popupBlur}>
      <div className={css.container}>
        Вы уверены, что хотите удалить это объявление?
        <div className={css.buttonsContainer}>
          <div className={css.button} onClick={close}>Отмена</div>
          <div className={css.button} onClick={deleteHandler}>Удалить</div>
        </div>
      </div>
    </div>
  )
}

export const ConfirmPopup = React.memo(ConfirmPopupFC)
