import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { history } from 'utils'
import { ADVERT, TAdvert } from 'requests/queries'
import { CreateAdvert } from 'components/CreateAdvert'

const EditAdvertFC = () => {
  const { state } = useLocation<{ advertId?: number }>()
  const advertId = useMemo(() => state.advertId, [state.advertId])

  if (!advertId) history.push('/createAdvert')

  const {
    data,
    error,
    loading,
  } = useQuery<{advert: TAdvert}>(ADVERT, { variables: { id: advertId } })

  if (loading) return <div>Загрузка</div>
  if (error) return <div>Ошибка</div>

  return (
    <CreateAdvert
      advert={data?.advert}
    />
  )
}

export const EditAdvert = React.memo(EditAdvertFC)
