import React, {
  Fragment,
  useState,
  useCallback,
} from 'react'
import { Link } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'

import map from 'lodash/map'
import parseInt from 'lodash/parseInt'

import { UPDATE_ADVERT_HIDDEN } from 'requests/mutations'
import { SELLER_ADVERTS, TSellerAdvertsResponse } from 'requests/queries'
import { getCurrencySymbol } from 'helpers'

import { ConfirmPopup } from './components/ConfirmPopup'
import css from './Adverts.module.css'

type TAdvertsProps = {
  sellerId: string,
}

const FAKE_ADVERT_ID = -1

const AdvertsFC = ({ sellerId }: TAdvertsProps) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [deletingAdvertId, setDeletingAdvertId] = useState<number>(FAKE_ADVERT_ID)
  const {
    data,
    refetch,
  } = useQuery<TSellerAdvertsResponse>(SELLER_ADVERTS, { variables: { id: sellerId } })

  const deleteButtonHandler = useCallback((advertId: number) => {
    setDeletingAdvertId(advertId)
    setIsPopupOpen(true)
  }, [])

  const [updateAdvert] = useMutation(UPDATE_ADVERT_HIDDEN, { onCompleted: () => refetch() })

  return (
    <Fragment>
      {isPopupOpen && (
        <ConfirmPopup
          advertId={deletingAdvertId}
          close={() => setIsPopupOpen(false)}
          refetch={refetch}
        />
      )}
      {data && map(data.seller.adverts, advert => (
        <div className={css.advertContainer}>
          <div className={css.advertBlock}>
            <div className={css.info}>{advert.model.title}</div>
            <div className={css.info}>{advert.condition.title}</div>
            <div className={css.info}>
              {`${advert.price.toLocaleString()} ${getCurrencySymbol(advert.currency)}`}
            </div>
            <div className={css.info}>
              {`${advert.is_banned ? 'Забанено администратором' : ' '}`}
              {`${!advert.is_banned && advert.is_hidden ? 'Скрыто пользователем' : ''}`}
            </div>
          </div>
          <div className={css.advertBlock}>
            <Link
              className={css.button}
              to={{
                pathname: `/editAdvert/${advert.id}`,
                state: { advertId: parseInt(advert.id) },
              }}
            >
              Редактировать
            </Link>
            <div
              className={css.button}
              onClick={() => updateAdvert({ variables: {
                id: advert.id,
                is_hidden: !advert.is_hidden,
              } })}
            >
              {advert.is_hidden ? 'Показать' : 'Скрыть'}
            </div>
            <div
              className={css.button}
              onClick={() => deleteButtonHandler(parseInt(advert.id))}
            >
              Удалить
            </div>
          </div>
        </div>
      ))}
    </Fragment>
  )
}

export const Adverts = React.memo(AdvertsFC)
