import React from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import isNull from 'lodash/isNull'

import { CORE_SELLER, TCoreSellerResponse } from 'requests/queries'
import { useStore } from 'hooks'

import { SellerInfo } from './components/SellerInfo'
import { Adverts } from './components/Advetrs'
import css from './UserPage.module.css'

const UserPageFC = () => {
  const {
    auth: { id },
  } = useStore()

  const { data, refetch } = useQuery<TCoreSellerResponse>(CORE_SELLER, { variables: { id } })

  return (
    <div className={css.container}>
      <div className={css.mainTitle}>
        Личный кабинет
      </div>
      <div className={css.mainSection}>
        <div className={css.advertsBlock}>
          {data && !isNull(data.user.seller) && <Adverts sellerId={data.user.seller.id} />}
          <Link className={css.button} to='/createAdvert'>Создать объявление</Link>
        </div>
        <div className={css.userInfoBlock}>
          {data && (
            <SellerInfo
              initialData={isNull(data.user.seller)
                ? undefined
                : {
                  address: data.user.seller.address,
                  dealerId: data.user.seller.dealer.id,
                  email: data.user.seller.email,
                  name: data.user.seller.name,
                  phone: data.user.seller.phone,
                  sellerId: data.user.seller.id,
                }}
              refetch={refetch}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export const UserPage = React.memo(UserPageFC)
