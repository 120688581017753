import { gql } from '@apollo/client'

export const ADVERTS_COUNT = gql`
  query GetAdvertsCount($where: JSON) {
    advertsConnection(where: $where) {
      aggregate {
        count
      }
    }
  }
`
