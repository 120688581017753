import React, {
  useState,
  useEffect,
  useMemo,
} from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import {
  BANNERS,
  TBannersResponse,
  CORE_SELLER,
  TCoreSellerResponse,
} from 'requests/queries'
import { useStore } from 'hooks'
import { BASE_URL } from 'config/URLs'

import css from './Header.module.css'

const COUNT_OF_BANNER = 1

export const HeaderFC = () => {
  const [bannerUrl, setBannerUrl] = useState<string>('')
  const [imageUrl, setImageUrl] = useState<string>('')
  const {
    data,
    error,
  } = useQuery<TBannersResponse>(BANNERS, { variables: { limit: COUNT_OF_BANNER } })

  const {
    auth: { id },
  } = useStore()

  const coreSellerResponse = useQuery<TCoreSellerResponse>(CORE_SELLER, { variables: { id } })

  const sellerName = useMemo(
    () => coreSellerResponse.data?.user.seller?.name,
    [coreSellerResponse.data],
  )

  useEffect(() => {
    if (!error && data) {
      setBannerUrl(data.banners[0]?.url || '')

      const imageUrlSuffix = data.banners[0]?.image[0]?.url || ''
      if (imageUrlSuffix) {
        setImageUrl(`${BASE_URL}${imageUrlSuffix}`)
      }
    }
  }, [data, error])

  return (
    <div className={css.container}>
      <div className={css.navBarContainer}>
        <div className={css.navBar}>
          <Link to='/'>
            <div className={css.logoContainer}>
              <div className={css.logo}>
                <div className={css.logoImage} />
              </div>
            </div>
          </Link>
          <div className={css.navSection}>
            <Link
              to='/news'
              className={css.contacts}
            >
              Новости
            </Link>
            <Link
              to='/contacts'
              className={css.contacts}
            >
              Контакты АМАЗОНЕ ООО
            </Link>
            <div className={css.iconsSection}>
              <div className={css.searchIcon} />
              <Link
                to='/userPage'
                className={css.myProfile}
              >
                {sellerName || 'Личный кабинет'}
              </Link>
            </div>
          </div>
        </div>
        <div className={css.decorativePolygon} />
      </div>
      {imageUrl && bannerUrl
        ? (
          <a href={bannerUrl}>
            <img
              className={css.banner}
              src={imageUrl}
              alt='не удалось загрузить Banner'
            />
          </a>
        ) : (
          <div className={css.bannerPlaceholder} />
        )
      }
    </div>
  )
}

export const Header = React.memo(HeaderFC)
