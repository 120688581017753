import React, { Fragment } from 'react'
import { Route } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { useStore } from 'hooks'

import { Header } from 'components/Header'
import { Footer } from 'components/Footer'
import { Login } from 'components/Login'
import { MainPage } from 'components/MainPage'
import { Advert } from 'components/Advert'
import { CreateAdvert } from 'components/CreateAdvert'
import { EditAdvert } from 'components/EditAdvert'
import { UserPage } from 'components/UserPage'
import { Contacts } from 'components/Contacts'
import { News } from 'components/News'
import { Article } from 'components/Article'

const AppFC = () => {
  const { auth: { isAuth } } = useStore()

  return (
    <Fragment>
      {(!isAuth) ? (
        <Login />
      ) : (
        <Fragment>
          <Header />
          <Route
            exact
            path='/'
            render={() => <MainPage />}
          />
          <Route
            path='/advert/:advertId'
            render={() => <Advert />}
          />
          <Route
            path='/createAdvert'
            render={() => <CreateAdvert />}
          />
          <Route
            path='/editAdvert'
            render={() => <EditAdvert />}
          />
          <Route
            path='/userPage'
            render={() => <UserPage />}
          />
          <Route
            path='/contacts'
            render={() => <Contacts />}
          />
          <Route
            path='/news'
            render={() => <News />}
          />
          <Route
            path='/article/:articleId'
            render={() => <Article />}
          />
          <Footer />
        </Fragment>
      )}
    </Fragment>
  )
}

export const App = observer(AppFC)
