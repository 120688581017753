import map from 'lodash/map'

import { FilterMain } from './Main'

export const FilterViews = FilterMain.views(self => ({
  get apolloFilter() {
    const where: {[key: string]: Array<string> | number | boolean} = {
      is_banned_eq: false,
      is_hidden_eq: false,
    }

    self.optionsFilters.forEach((checkedOptions, filterName) => {
      if (checkedOptions.length > 0) {
        where[`${filterName}_in`] = map(checkedOptions, option => option)
      }
    })

    self.rangeFilters.forEach((filterValues, filterName) => {
      const {
        from,
        max,
        min,
        to,
      } = filterValues

      if (from !== min || to !== max) {
        where[`${filterName}_gte`] = from
        where[`${filterName}_lte`] = to
      }
    })

    return {
      limit: self.resultsPerPage,
      start: (self.pageNumber - 1) * self.resultsPerPage,
      where,
    }
  },

  get queryParamsString() {
    let result = ''

    result += `page=${self.pageNumber}`

    self.optionsFilters.forEach((checkedOptions, filterName) => {
      if (checkedOptions.length > 0) {
        result += `&${filterName}`

        checkedOptions.forEach((checkedOption, idx) => {
          if (idx === 0) {
            result += '='
          } else {
            result += '_'
          }
          result += checkedOption
        })
      }
    })

    self.rangeFilters.forEach((filterValues, filterName) => {
      const {
        from,
        max,
        min,
        to,
      } = filterValues

      if (from !== min || to !== max) {
        result += `&${filterName}-=${from}_${to}`
      }
    })

    return result
  },
}))
