import React, { BaseSyntheticEvent } from 'react'
import { Link } from 'react-router-dom'

import parse from 'html-react-parser'

import { BASE_URL, IMAGE_PLACEHOLDER_URL } from 'config/URLs'

import css from './Article.module.css'

type TArticleProps = {
  date: string,
  expire_date: string | null,
  id: string,
  photo: string,
  text: string,
  title: string,
}

const ArticleFC = ({
  date,
  expire_date,
  id,
  photo,
  text,
  title,
}: TArticleProps) => (
  <div className={css.container}>
    <img
      src={`${BASE_URL}${photo}`}
      className={css.photo}
      onError={(e: BaseSyntheticEvent) => {
        e.target.error = null
        e.target.src = IMAGE_PLACEHOLDER_URL
      }}
      alt='не удалось загрузить фото'
    />
    <div className={css.mainSection}>
      <div className={css.date}>
        {date}
        {expire_date && <div className={css.expireDate}>{`Действует до ${expire_date}`}</div>}
      </div>
      <div className={css.title}>
        {title}
      </div>
      <div className={css.text}>
        {parse(text)}
      </div>
      <Link className={css.link} to={`/article/${id}`}>
        Подробнее...
      </Link>
    </div>
  </div>
)

export const Article = React.memo(ArticleFC)
