import { TLoginResponse } from '../types'
import { AuthSetters } from './Setters'
import { loginUser } from '../helpers/login-user'

export const AuthAPI = AuthSetters.actions(self => ({
  onFail() {
    if (!self.message) {
      self.setMessage('Произошла неизвестная ошибка')
    }
  },
  onSuccess(data: TLoginResponse) {
    self.setToken(data.jwt)
    self.setId(data.user.id)
    self.saveCookie({
      email: data.user.email,
      id: data.user.id.toString(),
      token: data.jwt,
    })
  },
})).actions(self => ({
  submitLogin: () => {
    if (!self.isFormValid) {
      if (!self.isPasswordValid) {
        self.setMessage('Пароль не может содержать менее 7 символов')
      }
      return
    }

    try {
      loginUser({
        data: {
          identifier: self.email,
          password: self.password,
        },
        onSuccess: self.onSuccess,
        setMessage: self.setMessage,
      })
    } catch {
      self.onFail()
    }
  },
}))
