import { TOptionsFilterProps, TRangeFilterProps } from './types'

type TOptionsFilterConfig = {
  type: 'options',
} & TOptionsFilterProps

type TRangeFilter = {
  type: 'range',
} & TRangeFilterProps

type TFilterConfig = TOptionsFilterConfig | TRangeFilter

export const FILTERS_LIST: Array<TFilterConfig> = [
  {
    getValueText: value => `${(value / 1000000).toFixed(2)}`,
    marksLabels: ['5 млн', '20 млн'],
    maxValue: 20000000,
    minValue: 5000000,
    param: 'price',
    step: 100000,
    title: 'Цена',
    type: 'range',
  },
  {
    collectionName: 'regions',
    title: 'Регион',
    type: 'options',
  },
  {
    collectionName: 'conditions',
    title: 'Состояние',
    type: 'options',
  },
  {
    collectionName: 'types',
    title: 'Тип техники',
    type: 'options',
  },
  {
    getValueText: value => `${(value).toFixed(1)}`,
    marksLabels: ['3 м', '15 м'],
    maxValue: 15,
    minValue: 3,
    param: 'working_width',
    step: 0.1,
    title: 'Ширина захвата',
    type: 'range',
  },
  {
    collectionName: 'models',
    title: 'Модель',
    type: 'options',
  },
  {
    collectionName: 'dealers',
    title: 'Дилер',
    type: 'options',
  },
]
