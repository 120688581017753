import React from 'react'

import Grid from '@material-ui/core/Grid'
import map from 'lodash/map'

import { TComponentProps } from '../../types'
import css from './Contacts.module.css'

const ContactsFC = ({ advert }: TComponentProps) => (
  <div className={css.container}>
      Контакты продавца
    <div className={css.dealerTitle}>
      {advert.dealer?.title}
    </div>
    {advert.dealer?.address}
    <Grid container>
      {map(advert.sellers, ({
        email,
        id,
        name,
        phone,
      }) => (
        <Grid item xs={12} sm={6} md={3} lg={12} key={String(id)}>
          <div className={css.sellerContainer}>
            <div className={css.boldSpan}>
              {name}
            </div>
            {phone}
            <br />
            <a href={`mailto:${email}`}>
              {email}
            </a>
          </div>
        </Grid>
      ))}
    </Grid>
  </div>
)

export const Contacts = React.memo(ContactsFC)
