import { gql } from '@apollo/client'

export const BANNERS = gql`
  query GetBanners($limit: Int, $start: Int, $where: JSON, $sort: String) {
    banners(limit: $limit, start: $start, where: $where, sort: $sort) {
      id
      url
      image {
        url
      }
    }
  }
`

type TBanner = {
  id: string,
  image: Array<{
    url: string,
  }>,
  url: string,
}

export type TBannersResponse = {
  banners: Array<TBanner>,
}
