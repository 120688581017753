import { gql } from '@apollo/client'

export const GEOGRAPHY = gql`
  query GetGeography {
    geographies(limit: 1) {
      image {
        url
      }
    }
  }
`

type TGeography = {
  image: {
    url: string,
  },
}

export type TGeographyResponse = {
  geographies: Array<TGeography>,
}
