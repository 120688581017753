import React, {
  useEffect,
  useCallback,
  useMemo,
} from 'react'
import { observer } from 'mobx-react-lite'

import { useStore } from 'hooks'

import { TRangeFilterProps } from '../../types'
import { Slider } from './Slider'
import css from './RangeFilter.module.css'

const RangeFilterFC = ({
  getValueText,
  marksLabels,
  maxValue,
  minValue,
  param,
  step,
  title,
}: TRangeFilterProps) => {
  const {
    filter: {
      addRangeFilter,
      rangeFilters,
      setRangeFilterState,
    },
  } = useStore()

  const handleChange = useCallback((
    event: React.ChangeEvent<{}>,
    newValue: number | Array<number>,
  ) => {
    const value = newValue as Array<number>
    setRangeFilterState({
      filterName: param,
      from: value[0],
      to: value[1],
    })
  }, [param, setRangeFilterState])

  const marks = useMemo(() => [
    {
      label: marksLabels[0],
      value: minValue,
    },
    {
      label: marksLabels[1],
      value: maxValue,
    },
  ], [marksLabels, maxValue, minValue])

  useEffect(() => {
    addRangeFilter({
      filterName: param,
      max: maxValue,
      min: minValue,
    })
  }, [addRangeFilter, maxValue, minValue, param])

  const currentValue = [
    rangeFilters.get(param)?.from || minValue,
    rangeFilters.get(param)?.to || maxValue,
  ]

  return (
    <div className={css.container}>
      <div className={css.title}>{title}</div>
      <Slider
        value={currentValue}
        onChange={handleChange}
        valueLabelDisplay='auto'
        max={maxValue}
        min={minValue}
        marks={marks}
        step={step}
        valueLabelFormat={getValueText}
      />
    </div>
  )
}

export const RangeFilter = React.memo(observer(RangeFilterFC))
