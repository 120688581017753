import get from 'lodash/get'
import { parseCookies } from 'nookies'
import { ApolloClient, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { createUploadLink } from 'apollo-upload-client'

import { BASE_URL } from 'config/URLs'
import { useStore } from 'hooks'

const httpLink = createUploadLink({
  uri: `${BASE_URL}/graphql`,
})

const withToken = setContext((_, { headers }) => {
  const token = typeof window === 'undefined' ? null : parseCookies().token

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const resetToken = onError((error: unknown) => {
  const { auth } = useStore()

  if (get(error, ['response', 'errors', 0, 'message']) === 'Invalid token.') {
    auth.logout()
  }
})

const authFlowLink = withToken.concat(resetToken)

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: authFlowLink.concat(httpLink),
})
