import axios, { AxiosRequestConfig } from 'axios'
import { BASE_URL } from 'config/URLs'

import { TLoginResponse } from '../types'

type TLoginUser = {
  data: {
    identifier: string,
    password: string,
  },
  onSuccess: (data: TLoginResponse) => void,
  setMessage: (message: string) => void,
}

export const loginUser = async({
  data,
  onSuccess,
  setMessage,
}: TLoginUser) => {
  const config: AxiosRequestConfig = {
    data,
    method: 'post',
    url: `${BASE_URL}/auth/local`,
  }

  return axios(config)
    .then((response) => {
      if (response.status === 200) {
        onSuccess(response.data)
      }
    })
    .catch((error) => {
      if (error.response) {
        setMessage(error.response.data.message[0].messages[0].message)
      }
    })
}
