const API_KEY = 'jPLAx82u9bLV88pokql1gR6C8l2IxAwWKC8_9m3qL4g'
const GEOCODE_URL = 'https://geocode.search.hereapi.com/v1/geocode'
const REVERSE_URL = 'https://revgeocode.search.hereapi.com/v1/revgeocode'

const checkStatus = (response: Response) => {
  if (!response.ok) {
    return Promise.reject(new Error(response.statusText))
  }

  return Promise.resolve(response)
}

const parseJSON = (response: Response) => response.json()

const callApi = (url: string) => fetch(url)
  .then(checkStatus)
  .then(parseJSON)
  .catch((error) => {
    // eslint-disable-next-line no-console
    console.error(error)
    return Promise.reject(error)
  })

export type TCoords = {
  lat: number,
  lng: number,
}

export type TResult = Array<{
  position: TCoords,
  title: string,
}>

type TResponse = {
  items: TResult,
}

const getAutocompleteResults = (query: string): Promise<TResponse> => {
  const url = `${GEOCODE_URL}?apiKey=${API_KEY}&q=${encodeURIComponent(query)}`

  return callApi(url)
}

const getAdress = ({ lat, lng }: TCoords): Promise<TResponse> => {
  const url = `${REVERSE_URL}?apiKey=${API_KEY}&at=${
    encodeURIComponent(`${lat},${lng}`)}`

  return callApi(url)
}

export const geocoder = { getAdress, getAutocompleteResults }
