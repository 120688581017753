type TSimpleFieldType = 'dropdown' | 'string' | 'number' | 'with_units'

type TUnit = {
  id: string,
  title: string,
}

export type TSimpleField = {
  fieldName: string,
  isRequired: boolean,
  title: string,
  type: TSimpleFieldType,
  units?: Array<TUnit>,
  unitsFieldName?: string,
}

const CURRENCIES = [
  { id: 'rubles', title: '₽' },
  { id: 'euros', title: '€' },
]

const OPERATING_TIME_UNITS = [
  { id: 'hours', title: 'ч' },
  { id: 'hectares', title: 'га' },
]

export const FIELDS: Array<TSimpleField> = [
  {
    fieldName: 'price',
    isRequired: true,
    title: 'Цена',
    type: 'with_units',
    units: CURRENCIES,
    unitsFieldName: 'currency',
  },
  {
    fieldName: 'operating_time',
    isRequired: true,
    title: 'Выработка',
    type: 'with_units',
    units: OPERATING_TIME_UNITS,
    unitsFieldName: 'operating_time_units',
  },
  {
    fieldName: 'model',
    isRequired: true,
    title: 'Модель',
    type: 'dropdown',
  },
  {
    fieldName: 'region',
    isRequired: true,
    title: 'Регион',
    type: 'dropdown',
  },
  {
    fieldName: 'country',
    isRequired: true,
    title: 'Страна',
    type: 'dropdown',
  },
  {
    fieldName: 'condition',
    isRequired: true,
    title: 'Состояние',
    type: 'dropdown',
  },
  {
    fieldName: 'type',
    isRequired: true,
    title: 'Тип техники',
    type: 'dropdown',
  },
  {
    fieldName: 'dealer',
    isRequired: true,
    title: 'Дилер',
    type: 'dropdown',
  },
  {
    fieldName: 'working_width',
    isRequired: true,
    title: 'Ширина захвата, м',
    type: 'number',
  },
  {
    fieldName: 'hopper_volume',
    isRequired: true,
    title: 'Объем бункера, л',
    type: 'number',
  },
  {
    fieldName: 'production_year',
    isRequired: true,
    title: 'Год производства',
    type: 'number',
  },
  {
    fieldName: 'serial',
    isRequired: true,
    title: 'Серийный номер',
    type: 'string',
  },
  {
    fieldName: 'equipment',
    isRequired: true,
    title: 'Комплектация',
    type: 'string',
  },
  {
    fieldName: 'extra_info',
    isRequired: false,
    title: 'Дополнительная информация',
    type: 'string',
  },
  {
    fieldName: 'video',
    isRequired: false,
    title: 'Ссылка на видео',
    type: 'string',
  },
]

export type TFilledSimpleField = {
  selectedUnitId?: string | null,
  value?: string,
} & TSimpleField
