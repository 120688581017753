import { gql } from '@apollo/client'

export const SELLERS = gql`
  query GetSellers($limit: Int, $start: Int, $where: JSON, $sort: String) {
    sellers(limit: $limit, start: $start, where: $where, sort: $sort) {
      id
      name
      email
    }
  }
`

type TSeller = {
  email: string,
  id: string,
  name: string,
}

export type TSellersResponse = {
  sellers: Array<TSeller>,
}
