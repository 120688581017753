import React, { BaseSyntheticEvent, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { useStore } from 'hooks'

import css from './Login.module.css'

export const LoginFC = () => {
  const { auth: {
    email,
    message,
    password,
    setEmail,
    setMessage,
    setPassword,
    submitLogin,
  } } = useStore()
  const [emailInput, setEmailInput] = useState(email)
  const [passwordInput, setPasswordInput] = useState(password)
  const [showPassword, setShowPassword] = useState(false)

  const handleSubmit = (e: BaseSyntheticEvent) => {
    e.preventDefault()
    setEmail(emailInput)
    setPassword(passwordInput)
    submitLogin()
  }

  const clearMessage = () => {
    setMessage('')
  }

  return (
    <div className={css.background}>
      <div className={css.formContainer}>
        <div className={css.logo} />

        <form className={css.form} onSubmit={handleSubmit}>
          <div className={css.inputs}>
            <input
              type='email'
              className={css.input}
              placeholder='Адрес электронной почты'
              onChange={(e: BaseSyntheticEvent) =>
                setEmailInput(e.target.value)}
              value={emailInput}
              onFocus={clearMessage}
            />
            <input
              type={showPassword ? 'text' : 'password'}
              className={css.input}
              placeholder='Пароль'
              onChange={(e: BaseSyntheticEvent) =>
                setPasswordInput(e.target.value)}
              value={passwordInput}
              onFocus={clearMessage}
            />
            <div
              className={css.iconEye}
              onClick={() => setShowPassword(!showPassword)}
            />
            {Boolean(message) && <div className={css.message}>{message}</div>}
          </div>
          <button type={'submit'} className={css.submitButton}>
            Войти
            <div className={css.iconArrow} />
          </button>
        </form>

        <div className={css.sub}>
          <span className={css.bold}>
            Amazone
          </span>
          {' - go for innovation'}
        </div>
      </div>
    </div>
  )
}

export const Login = React.memo(observer(LoginFC))
