import { gql } from '@apollo/client'

export const VIDEOS = gql`
  query GetVideos($limit: Int, $start: Int, $where: JSON, $sort: String) {
    videos(limit: $limit, start: $start, where: $where, sort: $sort) {
      id
      url
    }
  }
`
