import filter from 'lodash/filter'
import map from 'lodash/map'
import get from 'lodash/get'
import reduce from 'lodash/reduce'
import forEach from 'lodash/forEach'
import parseInt from 'lodash/parseInt'
import replace from 'lodash/replace'

import { EditAdvertMain } from './Main'

type TMutationData = {
  [key: string]: string | number | Array<number>,
  photos: Array<number>,
}

export const EditAdvertViews = EditAdvertMain.views(self => ({
  get checkedImagesCount() {
    return reduce(
      self.images,
      (total, image) => total + (image.isChecked ? 1 : 0),
      0,
    )
  },
  get filesToDelete() {
    const filteredImages = filter(
      self.images,
      image => !image.isChecked && get(image, 'id'),
    )

    return map(filteredImages, image => get(image, 'id'))
  },
  get imagesToUpload() {
    const filteredImages = filter(
      self.images,
      image => image.isChecked && get(
        image,
        ['file', 'name'],
        false,
      ),
    )

    return map(filteredImages, image => get(image, 'file'))
  },
  get mutationData() {
    const data: TMutationData = { photos: [] }
    forEach(self.simpleFields, (field) => {
      let parsedNumber = 0
      switch (field.type) {
        case 'string':
          data[field.fieldName] = field.value

          break
        case 'dropdown':
          parsedNumber = parseInt(field.value, 10)
          if (parsedNumber) {
            data[field.fieldName] = parsedNumber
          }

          break
        case 'number':
          data[field.fieldName] = field.fieldName === 'production_year'
            ? parseInt(field.value, 10)
            : parseFloat(replace(
              field.value,
              ',',
              '.',
            )) || 0

          break
        case 'with_units':
          data[field.fieldName] = parseInt(field.value, 10) || 0

          if (field.selectedUnitId) {
            data[field.unitsFieldName || ''] = field.selectedUnitId
          }

          break
        default:
      }
    })

    const filteredImages = filter(
      self.images,
      image => image.isChecked && get(image, 'id'),
    )
    data.photos = map(filteredImages, image => parseInt(get(image, 'id')))
    data.sellers = map(self.sellers, seller => parseInt(seller.id))
    data.address = self.address

    return data
  },
}))
