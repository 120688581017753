import { types, Instance } from 'mobx-state-tree'

const {
  array,
  boolean,
  enumeration,
  frozen,
  maybe,
  model,
  string,
  union,
} = types

const Unit = model({
  id: string,
  title: string,
})

const SimpleField = model('SimpleField', {
  fieldName: string,
  isRequired: boolean,
  selectedUnitId: maybe(string),
  title: string,
  type: enumeration(
    'fieldType',
    ['dropdown', 'string', 'number', 'with_units'],
  ),
  units: array(Unit),
  unitsFieldName: maybe(string),
  validationError: false,
  value: string,
}).actions(self => ({
  setSelectedUnitId(unitId: string) {
    self.selectedUnitId = unitId
  },
  setValidationError(flag: boolean) {
    self.validationError = flag
  },
  setValue(value: string) {
    self.value = value
  },
}))

const AbstractImage = model('AbstractImage', {
  isChecked: true,
  type: enumeration('imageType', ['new', 'uploaded']),
  url: string,
}).actions(self => ({
  toggle() {
    self.isChecked = !self.isChecked
  },
}))

const Image = AbstractImage.props({
  file: frozen({}),
})

const UploadedImage = AbstractImage.props({
  id: string,
})

const Seller = model('Seller', {
  email: string,
  id: string,
  name: string,
})

export const EditAdvertMain = model('EditAdvertMain', {
  address: '',
  images: array(union(UploadedImage, Image)),
  sellers: array(Seller),
  simpleFields: array(SimpleField),
})

export interface ISimpleField extends Instance<typeof SimpleField> {}
export interface IAbstractImage extends Instance<typeof AbstractImage> {}
