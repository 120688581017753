export const MONTHS = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря',
]

export const INFO_BLOCKS = [
  {
    path: ['model', 'title'],
    title: 'Модель:',
  },
  {
    path: ['type', 'title'],
    title: 'Тип:',
  },
  {
    path: ['condition', 'title'],
    title: 'Состояние:',
  },
  {
    path: ['serial'],
    title: 'Серийный номер:',
  },
  {
    path: ['equipment'],
    title: 'Комплектация:',
  },
  {
    path: ['working_width'],
    title: 'Ширина захвата, м:',
  },
  {
    path: ['hopper_volume'],
    title: 'Объем бункера, л:',
  },
  {
    path: ['production_year'],
    title: 'Год производства:',
  },
]

export const ADVERT_CONTAINER_ID = 'advertContainer'
