import { RefObject, useEffect } from 'react'

type TUseOutsideClickEffect = {
  onClick: Function,
  wrapperRef: RefObject<HTMLDivElement>,
}

export const useOutsideClickEffect = ({
  onClick,
  wrapperRef,
}: TUseOutsideClickEffect) => {
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const targetNode = event.target instanceof Node
        ? event.target
        : null

      if (wrapperRef.current && !wrapperRef.current.contains(targetNode)) {
        onClick()
      }
    }

    window.addEventListener('click', handleOutsideClick)

    return () => {
      window.removeEventListener('click', handleOutsideClick)
    }
  }, [onClick, wrapperRef])
}
