import { gql } from '@apollo/client'

export const SELLER_ADVERTS = gql`
  query GetSellerAdverts($id: ID!) {
    seller(id: $id) {
      adverts {
        id
        is_banned
        is_hidden
        model {
          title
        }
        price
        condition {
          title
        }
        currency
      }
    }
  }
`

type TAdvert = {
  condition: { title: string },
  currency: 'rubles' | 'euros',
  id: string,
  is_banned: boolean,
  is_hidden: boolean,
  model: { title: string },
  price: number,
}

export type TSellerAdvertsResponse = {
  seller: {
    adverts: Array<TAdvert>,
  },
}
