import {
  useState,
  useCallback,
  useEffect,
} from 'react'
import { useQuery, useMutation } from '@apollo/client'

import { useEmailValidation, useStore } from 'hooks'
import { getFilterOptions } from 'requests/templates'
import {
  TCreateSellerResponse,
  UPDATE_SELLER,
  CREATE_SELLER,
} from 'requests/mutations'

type TDealersResponse = {
  dealers: Array<{ id: string, title: string }>,
}

export type TSellerInfoProps = {
  initialData?: {
    address: string,
    dealerId: string,
    email: string,
    name: string,
    phone: string,
    sellerId: string,
  },
  refetch: Function,
}

export const useSellerInfo = ({ initialData, refetch }: TSellerInfoProps) => {
  const [getName, setGetName] = useState<() => string>(() => '')
  const [getEmail, setGetEmail] = useState<() => string>(() => '')
  const [getAddress, setGetAddress] = useState<() => string>(() => '')
  const [getPhone, setGetPhone] = useState<() => string>(() => '')

  const [dealerId, setDealerId] = useState('')
  const [sellerId, setSellerId] = useState('')

  const [dealerIdValidationError, setDealerIdValidationError] = useState(false)

  const {
    auth: { id, logout },
  } = useStore()

  const dealersResponse = useQuery<TDealersResponse>(getFilterOptions('dealers'))

  const validateEmail = useCallback(useEmailValidation(), [])
  const isNotEmpty = useCallback((s: string) => s !== '', [])

  const updateSeller = useMutation(UPDATE_SELLER, { variables: { id: sellerId } })
  const createSeller = useMutation<TCreateSellerResponse>(
    CREATE_SELLER,
    {
      onCompleted: (data) => {
        setSellerId(data.createSeller.seller.id)
        refetch()
      },
      variables: { userId: id },
    },
  )
  const [mutateSeller, mutateSellerResponse] = initialData ? updateSeller : createSeller

  const submitHandler = useCallback(() => {
    const name = getName()
    const email = getEmail()
    const address = getAddress()
    const phone = getPhone()

    if (!dealerId) {
      setDealerIdValidationError(true)
    }

    if (name
        && email
        && address
        && phone
        && dealerId
    ) {
      //@ts-expect-error
      mutateSeller({ variables: {
        address,
        dealerId,
        email,
        name,
        phone,
      },
      })
    }
  }, [dealerId, getAddress, getEmail, getName, getPhone, mutateSeller])

  useEffect(() => {
    if (initialData) {
      setDealerId(initialData.dealerId)
      setSellerId(initialData.sellerId)
    }
  }, [initialData])

  return {
    dealerIdValidationError,
    dealersResponse,
    isNotEmpty,
    logout,
    mutateSellerResponse,
    setDealerId,
    setGetAddress,
    setGetEmail,
    setGetName,
    setGetPhone,
    submitHandler,
    validateEmail,
  }
}
