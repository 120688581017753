import React from 'react'
import ReactDOM from 'react-dom'
import { Route, Router } from 'react-router-dom'
import { Provider as MobxProvider } from 'mobx-react'
import { ApolloProvider } from '@apollo/client'

import { rootStore } from 'stores'
import { apolloClient, history } from 'utils'
import { App } from 'components/App'


ReactDOM.render(
  <Router history={history}>
    <ApolloProvider client={apolloClient}>
      <MobxProvider {...rootStore}>
        <Route path='/' render={() => <App />} />
      </MobxProvider>
    </ApolloProvider>
  </Router>,
  document.getElementById('root'),
)
