import { useEffect, useMemo } from 'react'
import { useMutation, useQuery } from '@apollo/client'

import forEach from 'lodash/forEach'
import parseInt from 'lodash/parseInt'

import {
  TAdvert,
  TCoreSellerResponse,
  CORE_SELLER,
} from 'requests/queries'
import {
  FILES_UPLOAD,
  CREATE_ADVERT,
  UPDATE_ADVERT,
  DELETE_FILE,
} from 'requests/mutations'
import { useStore } from 'hooks'
import { history } from 'utils'

import { FIELDS } from './config'
import { mapAdvertToInitialState } from './helpers'

type TUploadResponse = {
  multipleUpload: Array<{id: string}>,
}

export const useCreateAdvert = (advert: TAdvert | undefined) => {
  const initialState = useMemo(() => (advert
    ? mapAdvertToInitialState(advert)
    : undefined), [advert])

  const {
    auth: {
      id,
    },
    editAdvert: {
      addSimpleField,
      filesToDelete,
      imagesToUpload,
      mutationData,
      reset,
      setAddress,
      setSellers,
      setUploadedImages,
      simpleFields,
      validateFields,
    },
  } = useStore()

  const { data, loading } = useQuery<TCoreSellerResponse>(CORE_SELLER, { variables: { id } })

  const seller = useMemo(() => data?.user.seller, [data])

  const [uploadImages] = useMutation<TUploadResponse>(FILES_UPLOAD)
  const [createAdvert] = useMutation(CREATE_ADVERT)
  const [updateAdvert] = useMutation(UPDATE_ADVERT)
  const [deleteFile] = useMutation(DELETE_FILE)

  const onSubmit = () => {
    if (validateFields()) {
      uploadImages({ variables: { files: imagesToUpload } })
        .then(({ data }) => {
          const enhancedMutationData = mutationData
          forEach(
            data?.multipleUpload,
            ({ id }) => enhancedMutationData.photos.push(parseInt(id)),
          )
          if (initialState) {
            enhancedMutationData.id = initialState.advertId
            updateAdvert({ variables: enhancedMutationData })
              .then(() => {
                forEach(filesToDelete, id => deleteFile({ variables: { id } }))
                history.push('/')
              })
          } else {
            createAdvert({ variables: enhancedMutationData })
              .then(() => history.push('/'))
          }
        })
    }
  }

  useEffect(() => {
    forEach(initialState?.fields || FIELDS, (field) => {
      addSimpleField({ field })
    })

    if (initialState) {
      setUploadedImages(initialState.images)
      setSellers({ sellers: initialState.sellers })
      setAddress(initialState.address)
    }

    return () => reset()
  }, [
    addSimpleField,
    initialState,
    reset,
    setAddress,
    setSellers,
    setUploadedImages,
  ])

  return {
    loading,
    onSubmit,
    seller,
    simpleFields,
  }
}
