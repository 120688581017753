import React, { BaseSyntheticEvent } from 'react'

import { BASE_URL, IMAGE_PLACEHOLDER_URL } from 'config/URLs'

import css from './ContactCard.module.css'

type TContactCardProps = {
  address: string,
  email: string,
  name: string,
  phone: string,
  photo: string,
}
const ContactCardFC = ({
  address,
  email,
  name,
  phone,
  photo,
}: TContactCardProps) => (
  <div className={css.container}>
    <img
      src={`${BASE_URL}${photo}`}
      className={css.photo}
      onError={(e: BaseSyntheticEvent) => {
        e.target.error = null
        e.target.src = IMAGE_PLACEHOLDER_URL
      }}
      alt='не удалось загрузить фото'
    />
    <div className={css.infoContainer}>
      <div className={css.name}>
        {name}
      </div>
      <div className={css.address}>
        {address}
      </div>
      <div className={css.phone}>
        {'Тел.: '}
        <a className={css.link} href={`tel:${phone}`}>
          {phone}
        </a>
      </div>
      <div className={css.email}>
        {'Электронная почта: '}
        <a className={css.link} href={`mailto:${email}`}>
          {email}
        </a>
      </div>
    </div>
  </div>
)

export const ContactCard = React.memo(ContactCardFC)
