import React, { useState, useMemo } from 'react'

import cx from 'classnames'
import map from 'lodash/map'

import { BASE_URL, IMAGE_PLACEHOLDER_URL } from 'config/URLs'

import { TComponentProps } from '../../types'
import css from './Images.module.css'

const ImagesFC = ({ advert }: TComponentProps) => {
  const [activeImageIndex, setActiveImageIndex] = useState(0)
  const [showFlippingborders, setShowFlippigBorders] = useState(false)

  const isSeveralPhoto = useMemo(() => advert.photos.length > 1, [advert])
  const isFirstPhoto = useMemo(() => activeImageIndex === 0, [activeImageIndex])
  const isLastPhoto = useMemo(
    () => activeImageIndex + 1 === advert.photos.length,
    [activeImageIndex, advert],
  )

  return (
    <div>
      <div
        className={css.mainImageContainer}
        onMouseEnter={() => setShowFlippigBorders(true)}
        onMouseLeave={() => setShowFlippigBorders(false)}
      >
        <img
          className={css.mainImage}
          src={`${BASE_URL}${advert.photos[activeImageIndex]?.url}`}
          onError={(e: React.BaseSyntheticEvent) => {
            e.target.error = null
            e.target.src = IMAGE_PLACEHOLDER_URL
          }}
          alt='Не удалось загрузить фото'
        />
        {isSeveralPhoto && (
          <div className={css.imagesCounter}>
            {`${activeImageIndex + 1} / ${advert.photos.length}`}
          </div>
        )}
        {isSeveralPhoto && !isLastPhoto && showFlippingborders && (
          <div
            className={css.flippingBorder}
            onClick={() =>
              setActiveImageIndex(activeImageIndex + 1)}
          >
            <div className={css.nextImage} />
          </div>
        )}
        {isSeveralPhoto && !isFirstPhoto && showFlippingborders && (
          <div
            className={cx(css.flippingBorder, css.flippingBorderLeft)}
            onClick={() =>
              setActiveImageIndex(activeImageIndex - 1)}
          >
            <div className={css.nextImage} />
          </div>
        )}
      </div>
      {isSeveralPhoto && (
        <div className={css.secondaryImagesContainer}>
          {map(advert.photos, ({ url }, idx) => (
            <img
              key={url}
              className={cx(
                css.secondaryImage,
                { [css.imageBorder]:
                  idx === activeImageIndex },
              )}
              src={`${BASE_URL}${url}`}
              alt='Не удалось загрузить фото'
              onClick={() => setActiveImageIndex(idx)}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export const Images = React.memo(ImagesFC)
