import { useCallback, useState } from 'react'

import domToImage from 'dom-to-image'
import { jsPDF } from 'jspdf'

import { ADVERT_CONTAINER_ID } from '../../config'

const A4_WIDTH = 210
const A4_HEIGHT = 297
const PDF_OFFSET = 5
const A4_ACTUAL_WIDTH = A4_WIDTH - (PDF_OFFSET * 2)
const A4_ACTUAL_HEIGHT = A4_HEIGHT - (PDF_OFFSET * 2)
const A4_ASPECT_RATIO = A4_ACTUAL_WIDTH / A4_ACTUAL_HEIGHT

export const useInfoBlock = (modelTitle: string) => {
  const [shareButtonText, setShareButtonText] = useState('Поделиться объявлением')

  const handleButtonDownload = useCallback(() => {
    const input = document.getElementById(ADVERT_CONTAINER_ID)
    // eslint-disable-next-line new-cap
    const pdf = new jsPDF()
    if (input) {
      const isImageWide = (input.offsetWidth / input.offsetHeight) > A4_ASPECT_RATIO
      const imageWidth = isImageWide
        ? A4_ACTUAL_WIDTH
        : A4_ACTUAL_HEIGHT / input.offsetHeight * input.offsetWidth

      const imageHeight = isImageWide
        ? A4_ACTUAL_WIDTH / input.offsetWidth * input.offsetHeight
        : A4_ACTUAL_HEIGHT

      domToImage.toPng(input)
        .then((imgData) => {
          pdf.addImage(
            imgData,
            'PNG',
            PDF_OFFSET,
            PDF_OFFSET,
            imageWidth,
            imageHeight,
          )
          pdf.save(`${modelTitle}.pdf`)
        })
    }
  }, [modelTitle])

  const handleButtonShare = useCallback(() => {
    navigator.clipboard.writeText(window.location.href)
    setShareButtonText('Ссылка скопирована!')
    setTimeout(() => setShareButtonText('Поделиться объявлением'), 2000)
  }, [])

  return {
    handleButtonDownload,
    handleButtonShare,
    shareButtonText,
  }
}
