import { gql } from '@apollo/client'

export const ADVERTS = gql`
  query GetAdverts($limit: Int, $start: Int, $where: JSON, $sort: String) {
    adverts(limit: $limit, start: $start, where: $where, sort: $sort) {
      id
      address
      working_width
      price
      currency
      model {
        title
      }
      production_year
      photos {
        url
      }
    }
  }
`

type TAdvert = {
  address: string,
  currency: string,
  id: number,
  model: { title: string },
  photos: Array<{url: string}>,
  price: number,
  production_year: number,
  working_width: number,
}

export type TAdvertsResponse = {
  adverts: Array<TAdvert>,
}
