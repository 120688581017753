import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import map from 'lodash/map'

import { TAdvert } from 'requests/queries'
import { ISimpleField } from 'stores/EditAdvert/models/Main'

import { AddressField } from './components/AddressField'
import { Sellers } from './components/Sellers'
import { SimpleField } from './components/SimpleField'
import { ImagesUpload } from './components/ImagesUpload'
import { useCreateAdvert } from './hooks'
import css from './CreateAdvert.module.css'

type TCreateAdvertProps = {
  advert?: TAdvert,
}

const CreateAdvertFC = ({ advert }: TCreateAdvertProps) => {
  const {
    loading,
    onSubmit,
    seller,
    simpleFields,
  } = useCreateAdvert(advert)

  if (loading) return <div className={css.warning}>Идет загрузка</div>
  // eslint-disable-next-line max-len
  if (!seller) return (<div className={css.warning}>Для создания и редактирования объявлений необходимо сначала заполнить данные о себе в <Link to='/userPage'>{' личном кабинете'}</Link></div>)

  return (
    <div className={css.outerContainer}>
      <div className={css.innerContainer}>
        <div className={css.title}>
          Новое объявление
        </div>
        <div className={css.subTitle}>
          *Обязательные поля
        </div>
        <div className={css.inputsContainer}>
          {map(simpleFields, (field: ISimpleField) => (
            <SimpleField
              field={field}
              key={field.title}
            />
          ))}
          <ImagesUpload />
          <Sellers />
          <AddressField />
        </div>
        <div className={css.buttonContainer}>
          <div
            className={css.button}
            onClick={onSubmit}
          >
            Сохранить
          </div>
        </div>
      </div>
    </div>
  )
}

export const CreateAdvert = React.memo(observer(CreateAdvertFC))
