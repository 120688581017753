import { gql } from '@apollo/client'

export const CORE_SELLER = gql`
  query GetCoreSeller($id: ID!) {
    user(id: $id) {
      seller {
        id
        address
        email
        name
        phone
        dealer {
          id
        }  
      }
    }
  }
`

type TSeller = {
  address: string,
  dealer: { id: string },
  email: string,
  id: string,
  name: string,
  phone: string,
}

export type TCoreSellerResponse = {
  user: {
    seller: TSeller,
  },
}
