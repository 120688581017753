import { withStyles } from '@material-ui/core/styles'
import DefaultSlider from '@material-ui/core/Slider'

export const Slider = withStyles({
  active: {},
  mark: {
    opacity: 0,
  },
  rail: {
    borderRadius: 4,
    height: 8,
  },
  root: {
    color: '#387d33',
    height: 3,
    padding: '13px 0',
  },
  thumb: {
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    height: 24,
    marginLeft: -12,
    marginTop: -8,
    width: 24,
  },
  track: {
    borderRadius: 4,
    height: 8,
  },
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
})(DefaultSlider)
