import { gql } from '@apollo/client'

export const UPDATE_SELLER = gql`
  mutation UpdateSeller(
    $id: ID!
    $name: String!
    $email: String!
    $address: String!
    $phone: String!
    $dealerId: ID!
  ) {
    updateSeller(
      input: {
        where: {
          id: $id
        }
        data: {
          name: $name
          address: $address
          email: $email
          phone: $phone
          dealer: $dealerId
        }
      }
    ) {
      seller {
        id
      }
    }
  }
`
