import { AuthStore } from './Auth'
import { FilterStore } from './Filter'
import { EditAdvertStore } from './EditAdvert'

const storesList = {}

export const rootStore = Object.assign(storesList, {
  auth: AuthStore,
  editAdvert: EditAdvertStore,
  filter: FilterStore,
})

export type TRootStore = typeof rootStore
