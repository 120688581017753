import map from 'lodash/map'
import get from 'lodash/get'

import { TAdvert } from 'requests/queries'

import { FIELDS } from './config'

export const mapAdvertToInitialState = (advert: TAdvert) => {
  const fields = map(FIELDS, field => ({
    ...field,
    selectedUnitId: get(advert, field.unitsFieldName || ''),
    value: String(get(
      advert,
      field.type === 'dropdown' ? [field.fieldName, 'id'] : field.fieldName,
    )),
  }))
  return {
    address: advert.address,
    advertId: advert.id,
    fields,
    images: advert.photos || [],
    sellers: advert.sellers || [],
  }
}
