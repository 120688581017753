import React, {
  BaseSyntheticEvent,
  useRef,
  useCallback,
} from 'react'
import { observer } from 'mobx-react-lite'

import cx from 'classnames'
import map from 'lodash/map'

import { IAbstractImage } from 'stores/EditAdvert/models/Main'
import { BASE_URL } from 'config/URLs'
import { useStore } from 'hooks'

import css from './ImagesUpload.module.css'

const ImagesUploadFC = () => {
  const {
    editAdvert: {
      addImages,
      checkedImagesCount,
      images,
    },
  } = useStore()

  const handleChange = useCallback((e: BaseSyntheticEvent) => {
    if (e.target.files.length > 0) {
      addImages(e.target.files)
    }
  }, [addImages])

  const hiddenFileInput = useRef(null)

  const handleClick = useCallback(() => {
    if (hiddenFileInput !== null) {
      //@ts-expect-error
      hiddenFileInput.current?.click()
    }
  }, [])


  return (
    <div className={css.container}>
      <div className={css.title}>
        Фотографии
        <br />
        <span className={cx(css.gray, { [css.red]: checkedImagesCount > 20 })}>
          {`Не более 20 (${checkedImagesCount})`}
        </span>
      </div>

      <input
        className={css.input}
        type='file'
        onChange={handleChange}
        accept='image/*'
        multiple
        ref={hiddenFileInput}
      />
      <div className={css.addPhoto} onClick={handleClick} />
      <div className={css.imagesContainer}>
        {map(images, ({
          isChecked,
          toggle,
          type,
          url,
        }: IAbstractImage) => (
          <div key={url} className={css.imageContainer}>
            <div
              className={cx(css.checkbox, { [css.checked]: isChecked })}
              onClick={toggle}
            />
            <img
              src={`${type === 'uploaded' ? BASE_URL : ''}${url}`}
              alt='Не удалось загрузить изображение'
              className={css.image}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export const ImagesUpload = React.memo(observer(ImagesUploadFC))
