/* eslint-disable react/no-unused-prop-types */
import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { useQuery } from '@apollo/client'

import map from 'lodash/map'
import toLower from 'lodash/toLower'

import { getFilterOptions } from 'requests/templates'
import { useStore } from 'hooks'

import { TOptionsFilterProps } from '../../types'
import { Multiselect } from '../Multiselect'
import css from './OptionsFilter.module.css'


type TOption = {
  id: string,
  title: string,
}

type TValue = {
  label?: string,
  value: string,
}

const OptionsFilterFC = ({
  collectionName,
  title,
}: TOptionsFilterProps) => {
  const {
    filter: {
      optionsFilters,
      setFilterOptions,
    },
  } = useStore()

  const {
    data,
    error,
    loading,
  } = useQuery(getFilterOptions(collectionName))

  const selectOptions = useMemo(() =>
    (data && data[collectionName] ? map(data[collectionName], ({
      id,
      title,
    }: TOption) => ({
      label: title,
      value: id,
    })) : []), [data, collectionName])

  const filterType = useMemo(() => {
    if (!data || !data[collectionName] || !data[collectionName][0]) return ''

    return toLower(data[collectionName][0].__typename)
  }, [data, collectionName])

  const dataMap: Record<string, TOption> | undefined = useMemo(() => {
    if (!data || !data[collectionName]) return undefined

    return data[collectionName].reduce((acc: Record<string, TOption>, item: TOption) => {
      acc[item.id] = item

      return acc
    }, {})
  }, [data, collectionName])

  const values = useMemo(() => {
    if (!dataMap) return []

    return optionsFilters?.get(filterType)?.map((value: string) => ({
      label: dataMap[value]?.title,
      value,
    })) || []
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionName, data, filterType, dataMap])

  const handleChange = (values: ReadonlyArray<TValue>) => {
    setFilterOptions(filterType, values.map(({ value }) => String(value)))
  }

  if (error) {
    return (<div>{`Ошибка фильтра ${title}`}</div>)
  }


  return (
    <div className={css.container}>
      {!loading && data[collectionName] && (
        <Multiselect
          placeholder={title}
          onChange={handleChange}
          options={selectOptions}
          values={values}
        />
      )}
    </div>
  )
}

export const OptionsFilter = observer(OptionsFilterFC)
