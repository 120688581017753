import React from 'react'
import { observer } from 'mobx-react-lite'
import { useQuery } from '@apollo/client'

import map from 'lodash/map'

import { useStore } from 'hooks'
import { CORE_SELLER, TCoreSellerResponse } from 'requests/queries'

import { AddSellers } from './components/AddSellers'
import css from './Sellers.module.css'

const SellersFC = () => {
  const {
    auth: { id },
    editAdvert: {
      deleteSeller,
      sellers,
      setSellers,
    },
  } = useStore()

  const {
    data,
    error,
    loading,
  } = useQuery<TCoreSellerResponse>(
    CORE_SELLER,
    {
      onCompleted: data => setSellers({
        onlyIfEmpty: true,
        sellers: [data.user.seller],
      }),
      variables: { id },
    },
  )

  return (
    <div className={css.container}>
      <div className={css.title}>
        Продавцы
      </div>
      <div className={css.inputContainer}>
        <div className={css.addSellersContainer}>
          <AddSellers />
        </div>
        <div className={css.selectedSellers}>
          {!loading && !error && map(sellers, ({
            email,
            id,
            name,
          }) => (
            <div className={css.sellerContainer} key={id}>
              {name}
              <div className={css.secondaryText}>
                {email}
              </div>
              {id !== data?.user.seller.id && (
                <div
                  className={css.buttonDelete}
                  onClick={() => deleteSeller(id)}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export const Sellers = React.memo(observer(SellersFC))
