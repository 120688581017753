import React, { useState, useCallback } from 'react'
import { Link } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'
import cx from 'classnames'
import times from 'lodash/times'

import { BASE_URL, IMAGE_PLACEHOLDER_URL } from 'config/URLs'
import { getCurrencySymbol } from 'helpers'

import css from './Advert.module.css'

type TAdvertProps = {
  address: string,
  currency: string,
  id: number,
  imagesURLs: Array<string>,
  model: string,
  price: number,
  workingWidth: number,
  year: number,
}

const MAX_NUMBER_OF_IMAGES = 5
const LAST_IMAGE_DELTA = 1
const MIN_IMAGE_COUNT_TO_SHOW_IMAGE_SWITCHER = 2

const AdvertFC = ({
  address,
  currency,
  id,
  imagesURLs,
  model,
  price,
  workingWidth,
  year,
}: TAdvertProps) => {
  const [advertHover, setAdvertHover] = useState(false)
  const [activeImageIndex, setActiveImageIndex] = useState(0)
  const numberOfDisplayedImages = imagesURLs.length > MAX_NUMBER_OF_IMAGES
    ? MAX_NUMBER_OF_IMAGES
    : imagesURLs.length
  const numberOfAdditionalImages = numberOfDisplayedImages
   === MAX_NUMBER_OF_IMAGES
    ? imagesURLs.length - MAX_NUMBER_OF_IMAGES
    : 0

  const isHaveMoreImages = () => numberOfAdditionalImages > 0
  && activeImageIndex === MAX_NUMBER_OF_IMAGES - LAST_IMAGE_DELTA

  const onMouseEnterHandler = useCallback(
    () => numberOfDisplayedImages >= MIN_IMAGE_COUNT_TO_SHOW_IMAGE_SWITCHER
      && setAdvertHover(true),
    [numberOfDisplayedImages],
  )

  const onMouseLeaveHandler = useCallback(
    () => numberOfDisplayedImages >= MIN_IMAGE_COUNT_TO_SHOW_IMAGE_SWITCHER
      && setAdvertHover(false),
    [numberOfDisplayedImages],
  )

  return (
    <Grid
      container
      spacing={2}
      justify='space-between'
      className={css.container}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
    >
      <Grid item xs={12} sm={4} lg={3}>
        <div className={css.imageContainer}>
          {advertHover && (
            <div
              className={cx(
                css.imageSwitchLayerContainer,
                { [css.blackout]: isHaveMoreImages() },
              )}
              onMouseLeave={() => setActiveImageIndex(0)}
            >
              {isHaveMoreImages()
                ? `Ещё ${numberOfAdditionalImages} фото`
                : ''
              }
              {times(numberOfDisplayedImages, counter => (
                <div
                  key={String(counter)}
                  className={css.imageSwitchLayer}
                  onMouseEnter={() => setActiveImageIndex(counter)}
                  style={{
                    left: `${100 / numberOfDisplayedImages * counter}%`,
                    width: `${100 / numberOfDisplayedImages}%`,
                  }}
                />
              ))}
            </div>
          )}
          <img
            src={`${BASE_URL}${imagesURLs[activeImageIndex]}`}
            className={css.image}
            onError={(e: React.BaseSyntheticEvent) => {
              e.target.error = null
              e.target.src = IMAGE_PLACEHOLDER_URL
            }}
            alt='не удалось загрузить фото'
          />

        </div>
      </Grid>
      <Grid item xs={12} sm={5} className={css.details}>
        <div>
          <div className={css.blackSpan}>
            <Link className={css.link} to={`/advert/${id}`}>
              {model}
            </Link>
          </div>
          <div className={css.graySpan}>{`${workingWidth} м`}</div>
          <div className={css.blackSpan}>{year}</div>
        </div>
        <div className={css.graySpan}>{address}</div>
      </Grid>
      <Grid item xs={12} sm={3} className={css.priceSection}>
        <div className={css.blackSpan}>
          {`${price.toLocaleString()} ${getCurrencySymbol(currency)}`}
        </div>
        <Link className={css.link} to={`/advert/${id}`}>
          <div className={css.showContactsButton}>
            Показать контакты
          </div>
        </Link>
      </Grid>
    </Grid>
  )
}

export const Advert = React.memo(AdvertFC)
