import React, { useEffect, useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { useQuery } from '@apollo/client'

import MUIPagination from '@material-ui/lab/Pagination'

import { ADVERTS_COUNT } from 'requests/queries'
import { useStore } from 'hooks'

import css from './Pagination.module.css'

const PaginationFC = () => {
  const {
    filter: {
      apolloFilter,
      pageNumber,
      resultsPerPage,
      setPageNumber,
    },
  } = useStore()
  const {
    data,
    error,
    loading,
    refetch,
  } = useQuery(ADVERTS_COUNT, { variables: apolloFilter })

  const handleChange = useCallback((
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPageNumber(value)
  }, [setPageNumber])

  useEffect(() => {
    refetch(apolloFilter)
  }, [apolloFilter, refetch])

  return (
    <div className={css.container}>
      {error && <div>Pagination query error</div>}
      {!loading && (
        <MUIPagination
          count={Math.ceil(
            data.advertsConnection.aggregate.count / resultsPerPage,
          )}
          page={pageNumber}
          onChange={handleChange}
          variant='outlined'
          shape='rounded'
        />
      )}
    </div>
  )
}

export const Pagination = React.memo(observer(PaginationFC))
