import React from 'react'
import { useQuery } from '@apollo/client'

import map from 'lodash/map'

import { ARTICLES, TArticlesResponse } from 'requests/queries'

import { Article } from './components/Article'
import css from './News.module.css'

const NewsFC = () => {
  const { data } = useQuery<TArticlesResponse>(ARTICLES, { variables: { sort: 'order' } })

  return (
    <div className={css.container}>
      <div className={css.title}>
        Новости
      </div>
      <div className={css.articlesContainer}>
        {data && map(data.articles, article => (
          <Article
            key={article.id}
            date={article.date}
            expire_date={article.expire_date}
            id={article.id}
            photo={article.media.url}
            text={article.text}
            title={article.title}
          />
        ))}
      </div>
    </div>
  )
}

export const News = React.memo(NewsFC)
