import { gql } from '@apollo/client'

export const UPDATE_ADVERT = gql`
  mutation UpdateAdvert(
    $id: ID!
    $serial: String!
    $equipment: String!
    $extra_info: String
    $address: String
    $working_width: Float!
    $hopper_volume: Float!
    $price: Int!
    $region: ID!
    $production_year: Int!
    $condition: ID!
    $type: ID!
    $model: ID!
    $dealer: ID!
    $video: String
    $operating_time: Int!
    $operating_time_units: ENUM_ADVERT_OPERATING_TIME_UNITS!
    $currency: ENUM_ADVERT_CURRENCY!
    $country: ID!
    $photos: [ID!]
    $sellers: [ID!]
  ) {

    updateAdvert(
      input: {
        where: {
          id: $id
        }
        data: {
          serial: $serial
          equipment: $equipment
          extra_info: $extra_info
          address: $address
          working_width: $working_width
          hopper_volume: $hopper_volume
          price: $price
          region: $region
          production_year: $production_year
          condition: $condition
          type: $type
          model: $model
          dealer: $dealer
          video: $video
          operating_time: $operating_time
          operating_time_units: $operating_time_units
          currency: $currency
          country: $country
          photos: $photos
          sellers: $sellers
        }
      }
    ) {
      advert {
        id
      }
    }
  }
`
