import { gql } from '@apollo/client'

export const ADVERT = gql`
  query GetAdvert($id: ID!) {
    advert(id: $id) {
      model {
        id
        title
      }
      type {
        id
        title
      }
      condition {
        id
        title
      }
      dealer {
        id
        title
        address
      }
      country {
        id
        title
        flag {
          url
        }
      }
      serial
      equipment
      extra_info
      address
      working_width
      hopper_volume
      id
      price
      currency
      photos {
        id
        url
      }
      production_year
      region {
        id
      }
      video
      operating_time
      operating_time_units
      sellers {
        id
        name
        phone
        email
      }
      published_at
    }
  }
`

export type TAdvert = {
  address: string,
  condition: {
    id: string,
    title: string,
  },
  country: {
    flag: {
      url: string,
    },
    id: string,
    title: string,
  },
  currency: string,
  dealer: {
    address: string,
    id: string,
    title: string,
  },
  equipment: string,
  extra_info: string,
  hopper_volume: number,
  id: string,
  model: {
    id: string,
    title: string,
  },
  operating_time: number,
  operating_time_units: string,
  photos: Array<{
    id: string,
    url: string,
  }>,
  price: number,
  production_year: number,
  published_at: string,
  sellers: Array<{
    email: string,
    id: string,
    name: string,
    phone: string,
  }>,
  serial: string,
  type: {
    id: string,
    title: string,
  },
  video: string,
  working_width: number,
}
