import { cast } from 'mobx-state-tree'

import includes from 'lodash/includes'
import split from 'lodash/split'
import forEach from 'lodash/forEach'
import endsWith from 'lodash/endsWith'
import parseInt from 'lodash/parseInt'
import trim from 'lodash/trim'

import { FilterViews } from './Views'

type TAddRangeFilter = {
  filterName: string,
  max: number,
  min: number,
}

type TSetRangeFilterState = {
  filterName: string,
  from: number,
  to: number,
}

export const FilterActions = FilterViews.actions(self => ({
  addRangeFilter({
    filterName,
    max,
    min,
  }: TAddRangeFilter) {
    const filter = self.rangeFilters.get(filterName)
    if (filter) {
      filter.min = min
      filter.max = max
      if (filter.from < min) {
        filter.from = min
      }
      if (filter.to > max) {
        filter.to = max
      }
    } else {
      self.rangeFilters.set(filterName, cast({
        from: min,
        max,
        min,
        to: max,
      }))
    }
  },
  reset() {
    self.optionsFilters.clear()
    self.rangeFilters.forEach((filter) => {
      filter.from = filter.min
      filter.to = filter.max
    })
  },
  setFilterOptions(filterName: string, options: Array<string>) {
    self.optionsFilters.set(filterName, options)
  },
  setPageNumber(number: number) {
    self.pageNumber = number
  },
  setRangeFilterState({
    filterName,
    from,
    to,
  }: TSetRangeFilterState) {
    const filter = self.rangeFilters.get(filterName)
    if (filter) {
      filter.from = from
      filter.to = to
    }
  },
  setSingleOptionInFilter(filterName: string, optionName: string) {
    const existingFilter = self.optionsFilters.get(filterName)
    if (existingFilter) {
      existingFilter.clear()
      existingFilter.push(optionName)
    } else {
      self.optionsFilters.set(filterName, [optionName])
    }
  },
  toggleFilterOption(filterName: string, optionName: string) {
    const existingFilter = self.optionsFilters.get(filterName)
    if (existingFilter) {
      if (includes(existingFilter, optionName)) {
        existingFilter.remove(optionName)
      } else {
        existingFilter.push(optionName)
      }
    } else {
      self.optionsFilters.set(filterName, [optionName])
    }
  },
})).actions(self => ({
  parseQueryString(queryString: string) {
    if (!queryString) return

    const [pageNumber, ...filters] = split(queryString, '&')

    self.pageNumber = parseInt(trim(pageNumber, 'page='))

    forEach(filters, (filter) => {
      const [filterName, options] = split(filter, '=')
      if (endsWith(filterName, '-')) { // rangeFilter
        const [from, to] = split(options, '_')
        const correctedName = filterName.slice(0, -1)

        if (self.rangeFilters.get(correctedName)) {
          self.setRangeFilterState({
            filterName: correctedName,
            from: parseInt(from),
            to: parseInt(to),
          })
        } else {
          self.addRangeFilter({
            filterName: correctedName,
            max: parseInt(to),
            min: parseInt(from),
          })
        }
      } else { // optionsFilter
        const parsedOptions = split(options, '_')
        forEach(parsedOptions, (option) => {
          self.toggleFilterOption(filterName, option)
        })
      }
    })
  },
}))
