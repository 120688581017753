export const getCurrencySymbol = (currency: string) => {
  switch (currency) {
    case 'euros':
      return '€'
    case 'rubles':
      return '₽'
    default:
      return 'Неизвестная валюта'
  }
}
