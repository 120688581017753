import React from 'react'

import { Dropdown } from 'components/common/Dropdown'

import { Input } from './components/Input'
import { TSellerInfoProps, useSellerInfo } from './hooks'
import css from './SellerInfo.module.css'

const SellerInfoFC = ({ initialData, refetch }: TSellerInfoProps) => {
  const {
    dealerIdValidationError,
    dealersResponse,
    isNotEmpty,
    logout,
    mutateSellerResponse,
    setDealerId,
    setGetAddress,
    setGetEmail,
    setGetName,
    setGetPhone,
    submitHandler,
    validateEmail,
  } = useSellerInfo({ initialData, refetch })

  return (
    <div className={css.container}>
      <div className={css.mainTitle}>
        Личные данные
      </div>
      <Input
        initialValue={initialData?.name}
        setGetValue={setGetName}
        title={'ФИО'}
        validateValue={isNotEmpty}
      />
      <Input
        initialValue={initialData?.email}
        setGetValue={setGetEmail}
        title={'Email'}
        validateValue={validateEmail}
      />
      <Input
        initialValue={initialData?.address}
        setGetValue={setGetAddress}
        title={'Адрес'}
        validateValue={isNotEmpty}
      />
      <Input
        initialValue={initialData?.phone}
        setGetValue={setGetPhone}
        title={'Телефон'}
        validateValue={isNotEmpty}
      />
      <div className={css.inputTitle}>
        Диллер
      </div>
      {dealersResponse.error && <div>Не удалось загрузить диллеров</div>}
      {dealersResponse.data && (
        <Dropdown
          defaultOptionId={initialData?.dealerId}
          handleChange={id => setDealerId(id)}
          options={dealersResponse.data.dealers}
          validationError={dealerIdValidationError}
        />
      )}
      {!mutateSellerResponse.loading && (
        <>
          <div className={css.button} onClick={submitHandler}>
            Сохранить
          </div>
          <div className={css.button} onClick={logout}>
            Выйти
          </div>
        </>
      )}
    </div>
  )
}

export const SellerInfo = React.memo(SellerInfoFC)
