import { cast } from 'mobx-state-tree'

import forEach from 'lodash/forEach'
import find from 'lodash/find'
import get from 'lodash/get'
import filter from 'lodash/filter'
import isNull from 'lodash/isNull'

import { TFilledSimpleField } from 'components/CreateAdvert/config'

import { ISimpleField } from './Main'
import { EditAdvertViews } from './Views'

type TAddSimpleField = {
  field: TFilledSimpleField,
}

export type TUploadedImage = {
  id: string,
  url: string,
}

export type TSeller = {
  email: string,
  id: string,
  name: string,
}

type TSetSellers = {
  onlyIfEmpty?: boolean,
  sellers: Array<TSeller>,
}

export const EditAdvertActions = EditAdvertViews.actions(self => ({
  addImages(images: FileList) {
    forEach(images, (image) => {
      if (!find(
        self.images,
        img => get(img, ['file', 'name']) === image.name
          && get(img, ['file', 'size']) === image.size,
      )) {
        self.images.push({
          file: image,
          type: 'new',
          url: URL.createObjectURL(image),
        })
      }
    })
  },
  addSeller(seller: TSeller) {
    if (!find(self.sellers, storedSeller => storedSeller.id === seller.id)) {
      self.sellers.push(seller)
    }
  },
  addSimpleField({ field }: TAddSimpleField) {
    const {
      fieldName,
      isRequired,
      selectedUnitId,
      title,
      type,
      units,
      unitsFieldName,
      value,
    } = field

    self.simpleFields.push(cast({
      fieldName,
      isRequired,
      selectedUnitId: !isNull(selectedUnitId) ? selectedUnitId : undefined,
      title,
      type,
      units,
      unitsFieldName,
      value: value || '',
    }))
  },
  deleteSeller(sellerId: string) {
    self.sellers = cast(filter(self.sellers, seller => sellerId !== seller.id))
  },
  reset() {
    self.simpleFields = cast([])
    self.images = cast([])
  },
  setAddress(address: string) {
    self.address = address
  },
  setSellers({ onlyIfEmpty, sellers }: TSetSellers) {
    if (onlyIfEmpty) {
      if (self.sellers.length === 0) {
        self.sellers = cast(sellers)
      }
    } else {
      self.sellers = cast(sellers)
    }
  },
  setUploadedImages(images: Array<TUploadedImage>) {
    forEach(images, (image) => {
      self.images.push({
        ...image,
        type: 'uploaded',
      })
    })
  },
  validateFields() {
    let isValid = true
    forEach(self.simpleFields, (field: ISimpleField) => {
      if (
        field.isRequired
          && (
            !field.value
            || (field.type === 'with_units' && !field.selectedUnitId
            ))) {
        field.setValidationError(true)
        isValid = false
      }
    })
    if (self.checkedImagesCount > 20) {
      isValid = false
    }

    return isValid
  },
}))
